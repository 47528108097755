
@keyframes spinner-animation
    0%
        opacity: 1
    100%
        opacity: 0

.spinner
    &__line
        transform-origin: 20px 20px
        animation: spinner-animation 1.2s linear infinite

        &::after
            content: ' '
            display: block
            position: absolute
            top: 2px
            left: 18px
            width: 2px
            height: 9px
            border-radius: 20%
            background: #333

        @for $i from 1 through 12
            $rotate: ($i - 1) * 30
            $delay: (0.1 * ($i - 1)) - 1.1
            &--#{$i}
                transform: rotate(#{$rotate}deg)
                animation-delay: #{$delay}s
