@use "include-media/dist/include-media"

.photoGalleryModule
    &__visualWrapper
        display: flex
        justify-content: center
        :not(.main--lg) > .photoGalleryModule:not(.module--lg,.module--md) &
            height: 260px
            @include include-media.media(">768px")
                height: 493px
    &__visual
        object-fit: scale-down !important

    &__wrapper
        position: relative
        overflow: hidden
        transition: height 0.3s

    ul
        list-style: none
        margin: 0
        padding: 0
        user-select: none

    &__roll
        position: absolute
        left: 0

        &--animated
            transition: left 0.3s

    &__item
        float: left
        width: 100%

    &__caption
        margin-top: 0
        padding-top: 1em

    &__caption.photoGalleryModule__caption--description:last-child
        border-color: #D4D4D4

    &__navBtn
        position: absolute
        width: 50px
        height: 50px
        background-color: rgba(0, 0, 0, 0.6)
        color: white
        cursor: pointer
        @include include-media.media(">768px")
            width: 80px
            height: 80px

        &::after
            font-size: 37px
            line-height: 50px
            width: 50px
            text-align: center
            display: block

            @include include-media.media(">768px")
                font-size: 60px
                line-height: 80px
                width: 80px

        &--previous
            &::after
                content: "<"

        &--next
            right: 0
            &::after
                content: ">"

    &__navCounter
        position: absolute
        background-color: rgba(0, 0, 0, 0.6)
        color: white
        top: 20px
        left: 20px
        padding: 5px 13px 5px 35px
        border-radius: 15px

        &::before
            content: ""
            width: 20px
            height: 20px
            position: absolute
            left: 10px
            background-image: url("assets/photo-gallery-icon.inline.svg")
            background-repeat: no-repeat
            background-position: 50% 50%

    &__navigation
        text-align: center

    &__navigationDot
        display: inline-block
        width: 6px
        height: 6px
        background-color: #d8d8d8
        border-radius: 50%
        margin: 8px 5px 5px 5px
        cursor: pointer
        transform: translateY(1.5px)

        &--active
            width: 9px
            height: 9px
            background-color: #4a4a4a
            cursor: default
            transform: none
