@use "include-media/dist/include-media"
@use "../../library/breakpoints"
@use "../../library/colors"
@use "../../library/fonts"
@use "../../library/variables"

/*-----------------------------------------------------------------------*/
/* ARTICLE MOBILE
/*-----------------------------------------------------------------------*/

=CapsuleBg
    @each $name, $color in colors.$capsuleBkg
        &.#{$name}
            background-color:  #{$color}


=CapsuleChapter
    @each $name, $color in colors.$navBorder
        &.#{$name}
            & .textModule--type-chapter
                color:  #{$color}

=ComplementaryInformationTitle
    @each $name, $color in colors.$navBorder
        &.#{$name}
            color:  #{$color}

=CapsuleInfraNote
    @each $name, $color in colors.$navSectionBorder
        &.#{$name}
            & .textModule--type-infraNote
                color:  #{$color}

=LinkBg
    @each $name, $color in colors.$capsuleBkg
        &.#{$name}
          background-color:  #{$color}

=ReviewColor
    @each $name, $color in colors.$navBorder
        &.#{$name}
            color: #{$color}

div.main.dossier
    .mainStory
        margin-top: 0

div.main
     .mainStory
        width: 100%
        padding: 0.75em
        position: relative
        max-width: 740px
        margin: 3.2rem auto 0
        div.articleBody .datewrapper
            margin-top: variables.$module-spacing
            min-height: 44px
        a
            color: inherit
            text-decoration: underline
        h1.headlines
            margin: 0 0 1em 0
            font-family: fonts.$fontVerlag
            .title
                display: inline-block
                width: 100%
                font-size: 2.9rem
                font-weight: 900
                line-height: 1.13em
                color: #1a1a1a
                margin-top: 1.5rem

            .suptitle
                display: inline-block
                width: 100%
                font-size: 1.4rem
                font-weight: normal
                line-height: 1.2em
                color: #686868
                margin-top: 1rem

        h1.headlines.hasFeaturedAuthor
            font-family: fonts.$fontSentinel
        .tag
            margin-bottom: 0
        header
            font-family: fonts.$fontVerlag
            font-weight: normal
            font-style: normal

            &.hasFeaturedAuthor
                .authorModule
                    padding: 0.65em
                    img
                        height: 100px
                        aspect-ratio: 1
                        object-fit: cover
                    .details
                        padding-left: 1.25rem

        .visual

            font-family: fonts.$fontVerlag
            width: 100%
            margin-top: variables.$module-spacing

            &__title
                font-weight: 700
                color: #131313
                font-size: 1.37rem
                padding-bottom: 1em

            img
                max-width: 100%
                max-height: 100%
                display: block
                aspect-ratio: auto 3/2
                object-fit: cover
            .description
                font-size: .9rem
                line-height: 1.33em
                color: #1a1a1a
                margin-top: 0.25em
                margin-bottom: 0
            .credit
                font-size: .75rem
                color: #8d8d8d
                text-transform: uppercase
                margin-bottom: 0
            p:last-child
                border-bottom: 1px solid colors.$DEF8
                padding-bottom: 0.7em

        .dates
            font-size: 0.75rem
            @include include-media.media(">=min")
                font-size: 1.0rem

        .authorModule
            display: flex
            margin: variables.$module-spacing 0 0 0
            flex-direction: column
            position: relative
            a
                position: relative
                text-decoration: none
            a::after
                color: #000
                content: ""
                position: absolute
                top: calc(50% - 8px)
                right: 20px
                width: 15px
                height: 15px
                border-top-width: 3px
                border-right-width: 3px
                border-top-style: solid
                border-right-style: solid
                transform: rotateZ(45deg)

            .authorModule__special--correspondent
                color: white
                padding: 5px
                text-transform: uppercase
                font-family: Verlag
                font-weight: 900
                font-size: 0.9rem
            .authorModule__content
                display: flex
                align-items: center
                background: #f3f3f3
                padding: 0.25rem
                width: 100%
                img
                    display: inline-block
                    height: 55px
                    aspect-ratio: 1
                    object-fit: cover
                .authorModule__details
                    display: flex
                    flex-direction: column
                    justify-content: center
                    width: 100%
                    padding: 0.65rem
                    font-size: .9rem
                    &:not(:has(.authorModule__name))
                        font-size: 1rem
                    .authorModule__name,
                    .authorModule__organisation
                        color: #000
                        font-family: fonts.$fontVerlag
                        display: inline-block
                        &--size-small
                            max-width: calc(100% - 55px - 0.75rem)
                    .authorModule__name
                        font-size: .9rem
                        font-weight: 900
                        margin-bottom: 0.2rem
                        text-transform: uppercase
            &--hasAuthorSheet .authorModule__content
                background-color: #f2f2f2
                &:hover
                    background-color: #dedede
        .teaser,
        .lead
            font-family: fonts.$fontVerlag
            font-size: 1.37rem
            font-weight: 600
            color: #222
            line-height: 1.4em

        .teaser.hasFeaturedAuthor,
        .lead.hasFeaturedAuthor
            font-family: fonts.$fontSentinel
        p.paragraph
            display: block
        p.textSeparator
            text-align: center
        p.textSeparator:before
            content: '***'
        p.paragraph, .listModule li
            font-family: fonts.$fontCalluna
            font-size: 1.1rem
            font-weight: 300
            font-style: normal
            line-height: 1.6em
            color: #131313
            margin: variables.$module-spacing 0 0 0
            strong
                font-weight: bold
        p.paragraph.hasFeaturedAuthor
            font-family: fonts.$fontSentinel
        p.epigraph
            font-family: fonts.$fontVerlag
            font-weight: 700
            color: #131313
            font-size: 1.25rem
            margin: variables.$module-spacing 0 0 0
            line-height: 1.5em

        p.signature
            font-family: fonts.$fontVerlag
            font-weight: 500
            color: #000000
            font-size: 1rem
            line-height: 1.3
            margin: 5px 0 variables.$module-spacing 0
            &:before
              content: "\2014\00A0"
              display: inline

        p.infraNote
            font-family: fonts.$fontVerlag
            font-weight: bold
            font-size: 1rem
            line-height: 1.3
            margin: variables.$module-spacing 0 0 0

        a.linkModule:before
            content: ""
            display: block
            background-color: var(--font-color, #4d4d4d) !important
            -webkit-mask: url(variables.$img-path + '/ico_externalLink.svg') no-repeat center center
            mask: url(variables.$img-path + '/ico_externalLink.svg') no-repeat center center
            width: 1em
            height: 1em
            padding-top: .44em
            margin-right: 10px
            mask-size: contain

        a.linkModule[href^="mailto:"]:before
            -webkit-mask: url(variables.$img-path + '/ico_mailto.svg') no-repeat center center
            mask: url(variables.$img-path + '/ico_mailto.svg') no-repeat center center
            mask-size: contain

        a.linkModule
            color: var(--font-color, #4d4d4d)  !important
            display: flex
            font-family: fonts.$fontVerlag
            font-size: 1.25rem
            font-weight: 400
            margin-top: variables.$module-spacing
            padding: 16px 16px 16px 16px
            text-decoration: underline
            +LinkBg

        button.linkModule 
            background-color: unset
            width: unset
            padding: 15px 35px
            margin-top: variables.$module-spacing

        .linkModule__content
            width: fit-content

        .listModule
            margin: 0

            & li
              margin-top: variables.$module-spacing
              padding-left: 10px

        .reviewModule
            background-color: colors.$DEF5
            padding: 20px
            margin-top: variables.$module-spacing
            +CapsuleBg
            +ReviewColor

            & .poster
              margin: 0 auto
              text-align: center
              float: left

              & img
                width: 100%
                @include include-media.media(">728px")
                    max-width: 217px
                margin-right: 20px

            & .details
              font-family: fonts.$fontVerlag
              font-size: 1.25rem
              font-weight: 300

              & .description
                margin-top: 18px
                margin-bottom: 18px

              & p
                margin-top: 0
                margin-bottom: 5px

              & .category
                color: #898989
                text-transform: uppercase
                font-size: 0.9rem

              & .name
                font-weight: 300
                font-style: italic
                font-size: 2rem

              & .director, .locationDate
                font-weight: 700
                font-size: 1.25rem

              & .rating
                font-size: 30px
                font-weight: 900

            & .clear
              clear: left

        .capsuleModule + .capsuleModule,
        .capsuleModule + .adsWrapper + .capsuleModule
            margin-top: 0

        .capsuleModule
            background-color: colors.$DEF5
            margin-top: calc(variables.$module-spacing * 2)
            padding: 20px 0
            @include include-media.media(">=min")
              padding: 20px
            +CapsuleBg
            +CapsuleChapter
            +CapsuleInfraNote

            & > *:first-child
              margin-top: 0 !important
              padding-top: 0 !important
            & > *:last-child
              margin-bottom: 0 !important
              padding-bottom: 0 !important

            & .textModule, li
              font-family: fonts.$fontVerlag
              color: #4d4d4d

            & .photoModule .photoModule__caption:last-child
                border-color: #c2c2c2

            & .photoGalleryModule
              & .photoGalleryModule__caption--description:last-child
                border-color: #c2c2c2

            & .textModule--type-chapter
              font-weight: 400
              text-transform: uppercase

            & .textModule--type-signature
              font-weight: 300
              line-height: 1.3
              color: #333333
            & .textModule--type-subhead, & .textModule--type-epigraph
              color: black

            & .audioModule_wrapper
              background-color: white

            & .listModule li
              margin-top: variables.$module-spacing
              padding-left: 0
              line-height: 1.7rem

            &--withMargin
              margin-top: 2.5rem !important

        .complementaryInformation
            background-color: colors.$DEF5
            font-family: Verlag, Arial, sans-serif
            padding: 20px
            margin: 20px 0
            +CapsuleBg


            .complementaryInformation__title
              text-transform: uppercase
              font-weight: 400
              font-size: 28px
              margin: 0
              padding: 0 0 10px 0
              +ComplementaryInformationTitle

            & ul
              margin: 0
              padding-left: 30px

            & div.source
              font-size: 14px
              color: #737373
              text-transform: uppercase
              line-height: 1.9em

            & li
              list-style-type: square
              padding-left: 10px
              padding-top: 10px

            dl
              margin: 0

            dl div
              padding-top: 8px

            span.descriptionList dt
              font-weight: 800
              font-size: 1.8em
              line-height: 1.5em

            span.descriptionList dd
              display: inline
              margin-left: 0
              line-height: 1.5em

            span.simpleDescriptionList dt
              font-weight: 600
              display: inline
              line-height: 1.5em
        .textModule
            &--type-chapter
                margin: variables.$module-spacing 0 0 0
                font-family: fonts.$fontVerlag
                font-size: 1.375rem
                font-weight: 700
                @include include-media.media(">728px")
                  font-size: 1.5rem
                @include include-media.media(">1024px")
                  font-size: 1.65rem
            &--type-chapter.hasFeaturedAuthor
                font-family: fonts.$fontSentinel
            &--type-subhead
                font-family: fonts.$fontVerlag
                font-weight: 700
                color: #131313
                font-size: 1.25rem
                margin: variables.$module-spacing 0 0 0
            &--type-subhead.hasFeaturedAuthor
                font-family: fonts.$fontSentinel
        .separatorModule
            display: block
            width: 100%
            border-top: 1px solid #000
            height: 1px
            margin: 2.4rem 0
/*-----------------------------------------------------------------------*/
/* ARTICLE MOBILE XTRA, NOMINATION & SUITE
/*-----------------------------------------------------------------------*/

.NOM div.main
    .mainStory
        .photoModule__visual
            width: auto
        header
            .photoModule
                background: white
                padding-bottom: 0
            .photoModule__visual
                margin-right: auto
                margin-left: auto
                display: block
                width: 45%
                max-width: 330px

        .articleBody
            .datewrapper
                height: 44px
                .dates
                    display: none

.XTR div.main,
.NOM div.main
    .mainStory
        h1.headlines
            font-family: fonts.$fontRobotoSerif !important
            .title
                display: block
                font-size: 1.5rem
                font-weight: 800
                color: #000000
                @include include-media.media(">=min")
                    font-size: 2.5625rem
                    font-weight: 700
                    line-height: 1.32em
                    margin-top: 1.6rem

            .suptitle
                display: block
                font-size: 1rem
                font-weight: 400
                @include include-media.media(">=min")
                    font-size: 1.25rem
                    font-weight: 300
                    margin-top: 1.9rem
        header
            font-family: fonts.$fontRobotoSans !important
            font-weight: normal
            font-style: normal

        .visual
            &__title
                font-family: fonts.$fontRobotoSerif
                font-size: 1.125rem
            .credit
                font-family: fonts.$fontRobotoSerif !important
                font-size: 0.75rem
                font-weight: 500
                @include include-media.media(">=min")
                    font-size: 0.8125rem
                    font-weight: 500

            .description
                font-family: fonts.$fontRobotoSans !important
                font-size: 0.8125rem
                font-weight: 400
                @include include-media.media(">=min")
                    font-size: 0.875rem
                    font-weight: 300

        .authorModule__content
            background: #e8e8e8
        .author
            .name,
            .organization
                font-size: 0.875rem
                font-family: fonts.$fontRobotoSerif !important
            .name
                font-family: fonts.$fontRobotoSerif !important
                font-weight: 700 !important
        .teaser,
        .lead
            display: block
            font-family: fonts.$fontRobotoSerif !important
            font-size: 1.125rem
            font-weight: 700
            line-height: 1.53em
            margin: variables.$module-spacing 0 0 0
            @include include-media.media(">=min")
                font-size: 1.4375rem
                font-weight: 600
                line-height: 1.61

        p.infraNote
            font-family: fonts.$fontRobotoSerif !important
            font-size: 0.9375rem
        p.signature
            font-family: fonts.$fontRobotoSerif !important
            font-size: 0.9375rem
            font-weight: 500
            color: #131313
            @include include-media.media(">=min")
                font-size: 1rem
                font-weight: 400

        p.paragraph,
        .listModule li
            font-family: fonts.$fontRobotoSans !important
            font-size: 1.0625rem
            font-weight: 400
            line-height: 1.47em
            strong
                font-weight: 800

            @include include-media.media(">=min")
                font-size: 1.125rem
                font-weight: 300
                line-height: 1.67em
                strong
                    font-weight: 700

        p.epigraph
            font-family: fonts.$fontRobotoSerif !important
            font-weight: 700
            font-size: 1.125rem
            @include include-media.media(">=min")
                font-size: 1.1875rem
                font-weight: 600

        a.linkModule
            font-family: fonts.$fontRobotoSerif !important
            font-size: 1.0625rem
            font-weight: 400
            line-height: 1.3em
            @include include-media.media(">=min")
                font-size: 1.0625rem

        .capsuleModule

            .textModule

                &--type-chapter
                    font-family: fonts.$fontRobotoSerif !important
                    font-size: 1.313rem
                    font-weight: 600
                    color: black
                    margin: variables.$module-spacing 0 0 0
                    @include include-media.media(">=min")
                        font-size: 1.625rem
                &--type-paragraph
                    font-size: 1.0625rem
                    font-weight: 400
                    color: #4d4d4d
                    @include include-media.media(">=min")
                        font-size: 1.125rem
                        font-weight: 300

                &--type-subhead
                    font-family: fonts.$fontRobotoSerif !important
                    font-size: 1.125rem
                    font-weight: 600
                    color: black
                    margin: variables.$module-spacing 0 0 0
                    @include include-media.media(">=min")
                        font-size: 1.1875rem

        .textModule
            &--type-paragraph
                color: #131313
            &--type-chapter
                font-family: fonts.$fontRobotoSerif !important
                font-size: 1.25rem
                font-weight: 700
                color: #131313
                margin: variables.$module-spacing 0 0 0
                @include include-media.media(">=min")
                    font-size: 1.625rem
                    font-weight: 600
            &--type-subhead
                font-family: fonts.$fontRobotoSerif !important
                font-size: 1.125rem
                font-weight: 700
                color: #131313
                margin: variables.$module-spacing 0 0 0
                @include include-media.media(">=min")
                    font-size: 1.1875rem
                    font-weight: 600
        .reviewModule
            .details
                font-family: fonts.$fontRobotoSerif !important
                color: #4d4d4d

                p
                    margin-bottom: 12px
            &__title
                font-size: 1.25rem !important
                font-weight: 400 !important
                @include include-media.media(">=min")
                    font-size: 1.625rem !important
                    font-weight: 300 !important

            &__author,
            &__publisher,
            &__pageCount
                font-family: fonts.$fontRobotoSans !important
                font-size: 1.0625rem !important
                font-weight: 400 !important
                @include include-media.media(">=min")
                    font-size: 1.125rem !important
                    font-weight: 300 !important

.XTR div.main
  .mainStory
    .capsuleModule .textModule,
    .textModule
      &--type-chapter
        font-family: fonts.$fontRobotoSerif !important
        font-size: 1.25rem
        font-weight: 700
        color: #131313
        margin: variables.$module-spacing 0 0 0
        text-transform: none
        @include include-media.media(">=min")
          font-size: 1.625rem
          font-weight: 600
      &--type-subhead
        font-family: fonts.$fontRobotoSerif !important
        font-size: 1.125rem
        font-weight: 700
        color: #131313
        margin: variables.$module-spacing 0 0 0
        @include include-media.media(">=min")
          font-size: 1.1875rem
          font-weight: 600
