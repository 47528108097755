.buttonVideoPlay__text
    position: relative
    top: 2.44rem
    font-family: rubrik, Arial, sans-serif
    font-size: 0.875rem
    font-weight: 300
    border: 0
    clip: rect(0 0 0 0)
    height: 1px
    overflow: hidden
    padding: 0
    color: #ffffff
