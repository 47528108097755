@use "include-media/dist/include-media"
@use "../../library/breakpoints"
@use "../../library/colors"
@use "../../library/fonts"

//Couleur de l'étiquette de section (élections, débats, dossier...)
=SectionLabelBg
    @each $name, $color in colors.$navBkg
        &.#{$name}
            .label
                background:  #{$color}

//Couleur de l'étiquette de section (commandité)
=SectionTagLineBg
    @each $name, $color in colors.$navBkg
        &.#{$name}
            .tagLine
                background:  #{$color}
body
    +SectionLabelBg
.tag,
.label,
.sectionNameBadge
    display: inline-block
    font-family: fonts.$fontVerlag
    font-size: 0.75rem
    color: #fff
    text-transform: uppercase
    padding: 0.5em 0.75em
    border-radius: 5px
.tag
    background-color: #ed1c23
    font-weight: 900
.label
    font-weight: bold

.sectionNameBadge
    font-weight: bold

    @each $name, $color in colors.$navBorder
        &--section-#{$name}
            background-color: #{$color}

    &--section-disabled
        background-color: #777

    &--section-PPF
        background-color: #D9C102

    &--section-XTR
        font-family: fonts.$fontRobotoSerif

//pour mobile
div.label
    text-align: center
    padding: 0.5em 0.75em 0.5em

// pour desktop
@include include-media.media("<833px")
    .main > div.label
        margin: 0 -1.15em
        padding: 1.35em 0.75em 0.5em
@include include-media.media(">832px")
    div.tags
        white-space: nowrap
        font-size: 0
    div.label
        display: none
    span.label
        margin-right: 4px
    span.tag
        margin-right: 4px
    .sectionNameBadge
        font-weight: 500
        line-height: 1em

.NOM,
.XTR
    .label,
    .sectionNameBadge
        font-family: fonts.$fontRobotoSerif
        letter-spacing: 0.04em
        font-weight: 600
        width: fit-content
        line-height: 1em
        @include include-media.media(">small")
            font-weight: 500
