@use "include-media/dist/include-media"
@use "../../library/fonts"

$CONTRIB: #ffe501
$CONTRIBHOVER: #ffe50190

.contributionBox
    width: calc(100% - 2.5rem)
    margin: 0 0.75rem 3rem 0.75rem
    padding: 2.5rem 2.125rem
    background-color: #F2F2F2
    font-family: fonts.$fontRubrik
    color: #000
    text-align: center
    &__title
        line-height: 1.95rem
        margin: 0
        padding: 0
        mark
            background-color: $CONTRIB
            line-height: 1.75rem
            font-weight: 600
            font-size: 1.375rem
            display: block
            width: fit-content
            margin: 0.24rem auto
            span
                display: block
                padding: 0 0.25rem
    &__txt
        font-weight: 400
        font-size: 0.94rem
        margin-bottom: 1.75rem
        line-height: 1.44rem
        strong
            font-weight: 600
    &__content
        margin: 1.5rem auto
    &__btn
        margin-top: 1.5rem
    a
        display: inline-block
        font-weight: 600
        font-size: 1rem
        padding: 0 4.785rem
        background-color: $CONTRIB
        line-height: 38px
        height: 40px
        border: 1px solid #262626
        border-radius: 1.5rem
        color: #262626
        text-align: center
        text-decoration: none
        &:hover
            background-color: $CONTRIBHOVER
    &.contributionBox--small
        padding: 2rem
        margin: 0 0 3rem 0
        width: 100%
        font-size: 15px
        .contributionBox__content
            margin: 1.13rem auto
            line-height: 1.5rem
        .contributionBox__title
            mark
                font-size: 1.25rem
        .contributionBox__wrapper
            margin: 0 auto
        .contributionBox__btn
            text-align: center
            padding: 0
        a
            padding: 0
            min-width: 16rem

        &__txt
            font-weight: 300
            font-size: 1rem
            margin-bottom: 1.75rem
            line-height: 1.6rem
            strong
                font-weight: 600

    @include include-media.media(">=min")
        &__title span
            display: none
