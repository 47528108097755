@use "../library/colors"


.autoPromoBox
    font-family: rubrik, Arial, sans-serif
    margin: 0 0 3rem
    padding: 0.1rem 0.75rem 0.75rem 0.75rem
    border: 1px solid colors.$DEF1

    &__title
        font-size: 1.4rem
        font-weight: bold
        text-transform: uppercase
        color: #232325
        margin: 20px 0 15px

    &__text
        background-color: colors.$DEF7
        padding: 15px 10px
        margin: 5px 0 0 0
        line-height: 1.5

        &:hover
            background-color: colors.$DEF8

    &__link
        display: inline-block
        text-decoration: none
        color: inherit

.autoPromoBox
    &--layout-mobile
        margin: 0 0.75rem 3rem 0.75rem
