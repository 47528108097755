@use "include-media/dist/include-media"
@use "../library/breakpoints"
@use "../library/fonts"
@use "../library/colors"
.dossierHeader
  position: relative
  margin: 0
  z-index: 1
  background-color: transparent
  @each $name, $color in colors.$dossierDarkBackground
    &--#{$name}
      background-color: #{$color}
  @media only screen and (min-width: breakpoints.$page-min-width)
    padding-bottom: 5.25rem
    margin: 0


  &__background
    position: absolute
    width: 100%
    height: 100%
    overflow: hidden
    z-index: -1
    @each $name, $color in colors.$dossierDarkBackground
      .dossierHeader--#{$name} &
        background-color: #{$color}


  &__backgroundVisual
    width: 100%
    height: 100%
    object-fit: cover
    -webkit-filter: blur(27px)
    filter: blur(27px)
    opacity: 0.4

  &__wrapper
    max-width: breakpoints.$page-full-width
    margin: 0 auto

  &__content
    padding-top: 32px
    display: block
    @media only screen and (min-width: breakpoints.$page-smallest-width)
      display: flex
      padding-top: 70px
      padding-bottom: 1.875rem
    @media only screen and (min-width: breakpoints.$page-min-width)
      padding-top: 1.875rem

  &__badgeContainer
    display: flex
    align-items: center

    &--head
      margin-bottom: 1.5rem

  &__visualWrapper
    width: 40%
    @media only screen and (min-width: breakpoints.$page-smallest-width)
      padding-left: 20px
    @media only screen and (min-width: breakpoints.$page-min-width)
      padding-left: 0

  &__visual
    width: 100%

  &__visualCredit
    color: colors.$PUBLISHED_DATE
    text-transform: uppercase
    font-family: fonts.$fontVerlag
    margin: 0.5rem 0.9rem 0.5rem 0.9rem
    font-size: 0.75rem
    @media only screen and (min-width: breakpoints.$page-smallest-width)
      font-size: 0.813rem
      margin: 0.5rem 0 0 0
    .dossierHeader--sponsored &
      font-family: fonts.$fontRobotoSerif
      font-size: 12px
      @media only screen and (min-width: breakpoints.$page-smallest-width)
        font-size: 13px



  &__articleDetail
    width: 100%
    color: white
    padding: 1rem 1rem 1.375rem
    @media only screen and (min-width: breakpoints.$page-smallest-width)
      width: 60%
      padding: 0 1.25rem 0 1.25rem
      margin: 0

  &__supTitle
    display: block
    font-size: 1.25rem
    margin-bottom: 0.688rem
    @media only screen and (min-width: breakpoints.$page-smallest-width)
      font-size: 1.5rem
      margin-bottom: 0.313rem
      margin-top: 0
    .dossierHeader--sponsored &
      font-family: fonts.$fontRobotoSerif
      font-size: 16px
      margin-bottom: 14px
      line-height: 1.13
      @media only screen and (min-width: breakpoints.$page-smallest-width)
        font-size: 20px
        line-height: 1.09

  &__title
    display: block
    font-family: fonts.$fontVerlag
    font-weight: 900
    font-size: 1.875rem
    margin-bottom: 1.875rem
    text-transform: uppercase
    @media only screen and (min-width: breakpoints.$page-smallest-width)
      font-size: 2.5rem
      margin-bottom: 1.688rem
    .dossierHeader--sponsored &
      font-family: fonts.$fontRobotoSerif
      font-weight: 700
      font-size: 24px
      line-height: 1.33
      @media only screen and (min-width: breakpoints.$page-smallest-width)
        font-size: 39px
        letter-spacing: 0.68px
        line-height: 1.41

  &__lead
    font-size: 1.375rem
    margin-top: 0
    margin-bottom: 0.625rem
    @each $name, $color in colors.$dossierLead
      .dossierHeader--#{$name} &
        color: #{$color}
    .dossierHeader--sponsored &
      font-family: fonts.$fontRobotoSerif
      font-weight: 600
      font-size: 17px
      line-height: 1.53
      @media only screen and (min-width: breakpoints.$page-smallest-width)
        font-size: 18px
        line-height: 1.56

  &__footer
    position: relative
    overflow: hidden
    width: 100%
    height: 2.2rem
    display: flex
    justify-content: space-between
    clip-path: inset(0)

  &__dates
    font-size: 0.875rem
    display: flex
    flex-direction: column
    justify-content: center
    font-family: rubrik, Arial, sans-serif
    color: colors.$PUBLISHED_DATE_LIGHT
    clip-path: inset(0)
    transition: 0.25s ease-in-out
    transition-delay: 0.25s

    .dossierHeader__footer--datesOpen &
      transition-delay: 0s
      clip-path: inset(0 250px 0 0)
      @media only screen and (min-width: breakpoints.$page-min-width)
        transition-delay: initial
        clip-path: initial

  &__dates .publicationsDate--type-update, &__dates .publicationsDate--type-publication
    color: #a2a2a2

  &__tabsWrapper
    width: 100%
    overflow: hidden
    position: sticky
    transition: top 0.2s ease-in-out
    top: 44px
    z-index: 100
    height: 0
    @media only screen and (min-width: breakpoints.$page-min-width)
      margin-top: -84px
      top: 90px
      height: auto
      &.dossierHeader__tabsWrapper--noSubSection
        top: 60px
    @each $name, $color in colors.$navBorder
      & .dossierHeader--#{$name}
        background-color: #{$color}

    &--top
      top: 0 !important

    &:not(&--pagination):not(&--sticky)
      background-color: transparent


  &__tabsContainer
    display: flex
    max-width: breakpoints.$page-full-width
    position: relative
    margin: 0 auto
    overflow: hidden
    z-index: 1

  &__tabs
    display: flex
    max-width: breakpoints.$page-full-width
    overflow: hidden

    .dossierHeader__tabsWrapper--pagination &
      padding: 0 84px

      & a
        min-width: 321px


  &__tab
    width: 321px
    display: flex
    height: 84px
    color: white
    text-decoration: inherit
    user-select: none
    @each $name, $color in colors.$dossierTabs
      .dossierHeader--#{$name} &
        background-color: #{$color}

    &--active
      background-color: #f9f9f8 !important
      color: black
      cursor: inherit
      pointer-events: none

  &__tabButton
    position: absolute
    z-index: 1
    width: 84px
    height: 84px
    background-color: rgba(0, 0, 0, 0.8)
    color: white
    cursor: pointer
    display: flex
    justify-content: center
    user-select: none

    &--disabled
      pointer-events: none
      background-color: rgba(0, 0, 0, 0.3)

    &--next
      left: calc(100% - 84px)

  &__tabButtonContent
    margin: auto
    font-size: 3rem
    font-family: Rubrik, sans-serif
    @each $name, $color in colors.$dossierDarkBackground
      .dossierHeader--#{$name} .dossierHeader__tabButton--disabled &
        color: #{$color}

  &__tab + &__tab
    margin-left: 0.125rem

  &__tabVisualWrapper
    width: 30%
    padding: 0
    display: flex
    flex-direction: column
    justify-content: center

  &__tabVisual
    padding: 0.375rem
    object-fit: cover
    aspect-ratio: 1
    object-position: center
    height: 100%

  &__tabTitle
    margin: auto 0.875rem
    width: 70%
    font-size: 0.875rem
    font-weight: 900
    text-transform: uppercase
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    .dossierHeader--sponsored &
      font-family: fonts.$fontRobotoSerif
      font-weight: 600
      font-size: 14px
      line-height: 1.21

  &__tabsPageIndicator
    width: 100%
    height: 30px
    position: absolute
    display: none
    text-align: center
    @media only screen and (min-width: breakpoints.$page-min-width)
      display: table-cell

  &__tabsPageIndicatorItem
    background-color: #d8d8d8
    height: 6px
    width: 6px
    border-radius: 50%
    display: inline-block
    vertical-align: middle

    &--active
      height: 9px
      width: 10px
      background-color: #4a4a4a

  &__tabsPageIndicatorItem + &__tabsPageIndicatorItem
    margin-left: 9px

  &__navBarWrapper
    position: sticky
    top: 44px
    z-index: 999
    @media only screen and (min-width: breakpoints.$page-min-width)
      display: none

  &__navBar
    justify-content: space-between
    padding: 0.75rem
    display: flex
    margin: 0 0 2rem 0
    position: relative
    @media only screen and (min-width: breakpoints.$page-smallest-width)
      margin: 0

    &--hidden
      display: none

  @each $name, $color in colors.$capsuleBkg
    &__navBar--#{$name}
      background-color: #{$color}

  &__navigationButtonsWrapper
    display: inherit

  &__navigationButton
    text-align: center
    width: 60px
    height: 1.5rem
    display: inline-block
    border-radius: 0.313rem
    color: white !important
    font-family: Rubrik, sans-serif
    font-size: 1rem
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.13
    letter-spacing: normal
    text-decoration: none !important
    padding: 0.18rem 1.438rem 0.188rem 1.438rem
    @each $name, $color in colors.$dossierTabs
      .dossierHeader__navBar--#{$name} &
        background-color: #{$color}
    .dossierHeader--sponsored &
      font-family: fonts.$fontRobotoSerif

    &--disabled
      pointer-events: none
      @each $name, $color in colors.$dossierDisabledTab
        .dossierHeader__navBar--#{$name} &
          background-color: #{$color}

  &__socialShare
    background-color: transparent !important
    clip-path: inset(0)
    width: 44px

    .buttons
      transition: inherit
      transform-origin: inherit
      right: 2.5em !important

    &.open
      width: 100%
      @media only screen and (min-width: breakpoints.$page-min-width)
        width: 50%

    a.print
      background-color: transparent
      height: 2.1em

    .share
      background: inherit
      filter: brightness(200%)

    .share img
      margin-top: calc((2.5em - 25px) / 2)
      filter: brightness(200%)

    .buttons li a
      background-color: inherit
      border: none
      filter: brightness(140%)

.topAnchor
  scroll-margin: 44px
