@use "include-media/dist/include-media"
@use "../library/breakpoints"
@use "../library/variables"
@use "../library/fonts"
@use "../library/colors"

.podcastEpisode__header
    display: block
    @include include-media.media(">=min")
        display: flex

.podcastEpisode__titleWrapper
    word-wrap: break-word

    @include include-media.media(">=min")
        width: 60%

.podcastEpisode__visualWrapper
    margin: 1rem auto 2rem auto
    border-radius: 1rem
    width: 14.5rem
    height: 14.5rem

    @include include-media.media(">=min")
        margin: 0 2rem 0 0

div.main .mainStory .podcastEpisode__titleWrapper h1.headlines
    margin-bottom: 0

div.main .mainStory .podcastEpisode__titleWrapper h1.headlines .suptitle
    margin-top: .5rem
    @include include-media.media(">=min")
        font-size: 1.4rem

div.main .mainStory .podcastEpisode__titleWrapper h1.headlines .title
    margin-top: 1rem
    margin-bottom: 0
    @include include-media.media(">=min")
        font-size: 2.5rem

.podcastLinks
    background-color: white
    text-align: center
    padding: 1rem
    font-size: 1.125rem
    line-height: 2rem
    margin: 2.5rem 0
    border: 1px solid #DEDEDE

.podcastLinks__link
    text-decoration: none !important
    font-weight: bold

.podcastLinks__link + .podcastLinks__link
    &:before
        content: "| "
