@use "../library/colors"
@use "include-media/dist/include-media"

.mostRecentList

    & &__wrapper
        padding-right: 0
        max-width: 740px

    .mostRecent__title
        margin-bottom: 30px

    &--tpl-section > .mostRecent__title
        margin-top: 35px

    article.mostRecentCard
        border-top: 1px solid colors.$DEF5
        @include include-media.media(">min")
            grid-template: auto auto / calc(30% + 15px) calc(70% - 15px)

        .mostRecentCard__title
            font-size: 1.75rem
            line-height: 1.14

        .mostRecentCard__lead
            font-size: 1.2rem
            line-height: 1.57rem

.mostRecent
    aside
        .slotWrapper
            margin-top: 0
