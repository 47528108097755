@use "../../../library/variables"

.buttonVideoPlay__iconPlaceholder:before
    background: url(variables.$img-path + "/video-icon.png")
    background-size: contain
    content: " "
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
