@use "variables"

@mixin declare-font-face-min($font-family, $font-directory, $font-filename, $font-weight: normal, $font-style: normal, $font-stretch: normal)
    @font-face
        font-family: '#{$font-family}'
        src: url("#{variables.$font-path}/#{$font-directory}/#{$font-filename}.woff2") format("woff2"), url("#{variables.$font-path}/#{$font-directory}/#{$font-filename}.woff") format("woff")
        font-weight: $font-weight
        font-style: $font-style
        font-stretch: $font-stretch

@mixin allCaps
    text-transform: uppercase


@mixin line-clamp($nbLines, $important: null)
    &
        position: relative
        overflow: hidden

    &::after
        content: ""
        text-align: right
        position: absolute
        bottom: 0
        right: 0
        width: 50%
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%)

    @supports (-webkit-line-clamp: $nbLines)
        &
            text-overflow: ellipsis
            display: -webkit-box
            -webkit-line-clamp: $nbLines $important
            -webkit-box-orient: vertical

        &::after
            display: none
