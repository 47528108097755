
.button
    &--theme-dark
        color: white
        border-color: white
        background-color: black

        &:hover
            background-color: #454545

        &:active
            background-color: #fafafa
            color: black
