@use "include-media/dist/include-media"
@use "../../library/colors"
@use "../../library/fonts"

/*-----------------------------------------------------------*/
/* Scoreboard
/*-----------------------------------------------------------*/
#scoreboard
    margin-bottom: 2rem

@keyframes sliderAnim
    0%
        height: 0
    80%
        height: 0
    90%
        height: 225px
    100%
        height: auto

@-moz-keyframes sliderAnim
    0%
        height: 0
    80%
        height: 0
    90%
        height: 225px
    100%
        height: auto

@-webkit-keyframes sliderAnim
    0%
        height: 0
    80%
        height: 0
    90%
        height: 225px
    100%
        height: auto

@-o-keyframes sliderAnim
    0%
        height: 0
    80%
        height: 0
    90%
        height: 225px
    100%
        height: auto

@-ms-keyframes sliderAnim
    0%
        height: 0
    80%
        height: 0
    90%
        height: 225px
    100%
        height: auto

.scribblelive
    font-family: fonts.$fontVerlag
    font-size: 16px
    margin: 0 0 1rem
    width: 100%

    /*-----------------------------------------------------------*/
    /* formulaire posez une question
    /*-----------------------------------------------------------*/
    #scribbleComments
        display: none
        margin: 0 0 2rem 0
        padding: 1rem 2rem
        background: colors.$DEF2
        .scribblelive__commentsTitle
            margin: 1em 0
            font-family: Verlag,Arial,sans-serif
            font-size: 1.37rem
            font-weight: 900
            @media (min-width: 729px)
                font-size: 1.5rem
            @media (min-width: 1025px)
                font-size: 1.65rem
    #inputContainer
        padding-bottom: 1rem
    #scrb-comment
        display: inline-block
        width: 100%
        resize: none
        line-height: 1.2rem
        padding: .5rem
        margin: 1rem 0
        font-size: 1rem
        border: 1px solid #c5c5c5
    .nom
        width: calc(60% - 1.2rem)
        display: inline-block
        vertical-align: middle
    input[type="text"],
    #scrb-name
        width: 100%
        line-height: 1.2em
        padding: .5rem
        font-size: 1rem
        border: 1px solid #c5c5c5
    #submitBtn
        width: 40%
        display: inline-block
        margin-left: 10px
        text-align: center
        padding: .5rem
        background-color: #999
        color: #fff
        text-transform: uppercase
        font-family:  fonts.$fontVerlag
        vertical-align: middle
        line-height: 1.2em
        font-size: 1rem
        border: 1px solid #999
        cursor: pointer
        &:hover
            background-color: #757373
            border: 1px solid #757373
    #scrb-msg
        font-size: 1rem
        text-align: left
        color: #919191
        margin: 1rem 0 0
        .err
            color: #f00
            font-size: .85rem
/*-----------------------------------------------------------*/
/* placeholders: ne pas combiner les déclarations
/*-----------------------------------------------------------*/

.err::-webkit-input-placeholder
    color: #f00
.err:-moz-placeholder
    color: #f00
.err::-moz-placeholder
    color: #f00
.err:-ms-input-placeholder
    color: #f00

/*-----------------------------------------------------------*/
/* les faits
/*-----------------------------------------------------------*/
#pinnedFactsList
    width: 100%
    clear: both
    float: none
    margin: 0 0 2rem
    display: none
    font-size: 16px
    li
        list-style-type: none
    .scribblelive__factListTitle
        background: colors.$DEF2
        border-top: 2px solid #4d4d4d
        border-bottom: 2px solid #4d4d4d
        padding: 1rem 2rem
        @media (min-width: 729px)
            font-size: 1.5rem
        @media (min-width: 1025px)
            font-size: 1.65rem

    .imageAvatar,
    .Meta
        display: none
    .Content
        margin: 1rem 2rem
        li
            list-style-type: disc
            margin: 1rem 0 0 1.5rem
/*-----------------------------------------------------------*/
/*  posts
/*-----------------------------------------------------------*/
#recentPostsList
    margin: 0 0 2rem 0
    padding: 1rem 2rem
    background: colors.$DEF2

    .scribblelive__recentPostsListTitle
        margin: 1em 0
        font-family: Verlag,Arial,sans-serif
        font-size: 1.37rem
        font-weight: 900
        @media (min-width: 729px)
            font-size: 1.5rem
        @media (min-width: 1025px)
            font-size: 1.65rem
    /*
        iframe
            max-width: fit-content !important
ul.recentPostsWidget
    list-style-type: none
    font-size: 18px
    width: 100%
    padding: 0 !important
    float: none !important
    li
        margin: 1rem 0 2rem
        padding: 1.5rem
        list-style-type: none
        float: none
        width: 100%
        background: #FFF
        & > img
            float: none
            display: inline-block
            vertical-align: middle
            margin-bottom: 10px

        .Content
            color: #333
            margin-left: 0
            border: 0 solid #e1e8ed
            border-radius: 4px
            padding: 20px 0 12px
            overflow-wrap: break-word
            width: inherit !important
            img
                float: none
                margin: 0 0 10px
                width: 100%
            blockquote
                width: 94%
                margin-left: 5%
                margin-bottom: 1rem
                border-left: 4px solid colors.$DEF2
                padding-left: 1rem
                margin-top: 1rem

        .Meta
            font-size: 14px
            margin-left: 10px
            margin-bottom: 10px
            display: inline-block
            vertical-align: middle
            width: calc(100% - 60px)
            color: #a7a7a7
            & > a, a
                color: #000
            .creatorName
                font-size: 18px
                color: #55acee
                font-weight: bold
            .creatorDate
                font-size: 16px
                color: #a2a9ae
            .ServerTime
                display: none
        &.twitter-post .Content
            padding: 0
dd.Voting
    color: green

.imageAvatar
    border-radius: 50%
    border: 1px solid lightgrey
    width: 39px
    height: 39px

.TopContentClass
    width: 100%
    height: 30px
    background-color: #55acee

/*-----------------------------------------------------------*/
/* mini sondage
/*-----------------------------------------------------------*/

.recentPostsWidget
    dl
        margin-bottom: 1rem
    dt
        display: block
        font-family:  fonts.$fontVerlag
        font-size: 18px
        margin-bottom: 1rem
        color: #6b6b6e
    dd
        display: block
        margin-bottom: .5rem
        input[type="radio"]
            margin: 1px 10px 0 0
            vertical-align: top
            font-size: 14px
            line-height: 15px
            display: inline-block
        span
            display: inline-block
            width: calc(100% - 35px)
            vertical-align: top
            line-height: 15px

.containerHidden
    display: none
.unavailable
    display: none
    padding: 10px

.youtube-post iframe, iframe.youtubePlayer, ul.recentPostsWidget li .Content video

    height: calc(521px * 9 / 16)
    width: 100%

.instagram-media.instagram-media-rendered
    width: calc(100% - 300px - 13rem)
    min-width: 300px !important
    @include include-media.media("<900px")
        width: calc(100% - 13rem)

.twitter-post,
.twitter-tweet.twitter-tweet-rendered
    margin: 10px 0
