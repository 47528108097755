
@use "../../library/breakpoints"
@use "../../library/variables"
@use "../../library/fonts"

/*---------------------------------------------*/
/* Component
/*---------------------------------------------*/

div.main .mainStory > .socialShare:before, .mainContent__podcastFooter > .socialShare:before
        display: inline-block
        content: "Partager cet article"
        line-height: 40px
        margin-left: 0.75rem
        font-size: 1rem
        font-weight: 500
        font-family: fonts.$fontRubrik
.mainContent__podcastFooter > .socialShare:before
  content: "Partager cette page"
.mainContent__podcastFooter > .socialShare:before
  margin: 0 1rem 0 0

.mainStory > .socialShare, .mainContent__podcastFooter .socialShare
    position: relative
    width: auto
    text-align: left
    margin: 33px 0 20px 0
    & a.print
        display: none
    .buttons
        position: relative
        display: inline-block
        right: inherit
        li
            display: inline-block

.datewrapper
    .socialShare.active
        width: auto
