@use "__iconPlaceholder/buttonVideoPlay__iconPlaceholder"
@use "__text/buttonVideoPlay__text"

.buttonVideoPlay
    min-width: 7.5rem
    min-height: 7.5rem
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    position: absolute
    border-radius: 50%
    border: 0
    background-color: rgba(0,0,0,.5)
    font-size: 3em
    line-height: 1.5em
    display: block
    padding: 0
    cursor: pointer
    opacity: 1
    text-align: center

    &:hover, &:focus, &--hover, &--focus
        background-color: rgba(102, 102, 102, 0.5)
