@use "include-media/dist/include-media"
@use "../../library/colors"
@use "../../library/fonts"

=MostRecentBorder
    @each $name, $color in colors.$navBorder
        &.#{$name}
            border-top: 4px solid  #{$color}
=SectionTagBg
    @each $name, $color in colors.$navBorder
        &.#{$name} .label
            background:  #{$color}
=trendingHover
    &:hover .visual img
        opacity: 0.9
    @each $name, $color in colors.$navBorder
        &.#{$name}:hover .title,
        &.#{$name}:hover .supTitle
            color: #{$color}

.trending
    +MostRecentBorder
    counter-reset: popHour popDays popWeek
    margin-bottom: 1rem
    &.now
        li
            counter-increment: popHour
        a.visual:before
            content: counter(popHour, decimal-leading-zero)
    &.today
        li
            counter-increment: popDays
        a.visual:before
            content: counter(popDays, decimal-leading-zero)

    &.weekly
        li
            counter-increment: popWeek
        a.visual:before
            content: counter(popWeek, decimal-leading-zero)

    &__title
        font-family: fonts.$fontRubrik
        font-size: 1.4rem
        font-weight: bold
        text-transform: uppercase
        color: #232325
        padding: 0.35rem 0
        margin-top: 1.15rem
        margin-bottom: 1.15rem

        @include include-media.media("<833px")
            font-size: 1.75rem

        .sectionName
            font-weight: 500
            font-size: 1.02em
            display: block

        .timeframe
            font-size: 1rem
            font-weight: normal
            display: block
            padding-top: 0.35rem
    ol
        display: block
        margin: 0
        padding: 0
        &:after
            height: 0
            width: 50%
            content: ""
    a.visual
        position: relative
        display: inline-block
        vertical-align: top
        padding: 0
        border: 0
        margin-bottom: 0
        margin-right: 0.75rem
        width: 80px
        height: 80px
        &:before
            color: #000
            bottom: auto
            left: 91px
            top: 0
            position: absolute
            font-family: fonts.$fontVerlag
            font-weight: bold
            font-size: 40px
            line-height: 30px
            display: block
            z-index: 10
        img
            object-fit: cover
            height: 80px
            max-width: 80px

    .articleDetail
        position: relative
        //padding: 1rem 0 10px 0
        white-space: normal
        display: inline-block
        vertical-align: top
        width: calc(100% - 80px - 0.75rem)
        a
            color: inherit
            text-decoration: none
        p
            margin: 0
        .storyCard__badgeCollection
            margin-left: 52px
            padding-bottom: 1em

    .title
        font-size: 1rem
        font-weight: bold
        color: #000
        line-height: 1.25rem
        margin-top: 0.3em
        display: block
    .hasFeaturedAuthor
        font-family: fonts.$fontSentinel
    .sectionNameBadge
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        max-width: 72%
    .sectionNameBadge,
    .tag
        position: absolute
        top: 0
        display: inline-block
        margin-left: 52px
    .supTitle
        color: #686868
        display: block
        //margin-top: 1.5rem
        font-size: 0.875rem
        font-weight: normal
    li.card
        display: block
        position: relative
        white-space: nowrap
        margin: 0 0 0.5rem
        padding: 1em 0
        border-top: 1px solid colors.$DEF8
        font-family: fonts.$fontVerlag
        +trendingHover

        .datewrapper
            padding-left: 95px

            .dates
                height: calc(2.5em + 4px)

    li.card.hasTag
        +SectionTagBg
        .articleDetail
            padding-top: 3.5rem
            &:before
                top: 2.5rem
        a.visual
            &:before
                top: 2.5rem
        .tags
            position: absolute
            top: 0
            left: 0
            margin-top: 0

    .dates
        font-size: 0.75rem
    .tags
        white-space: nowrap
        overflow: hidden
        max-width: 100%
        & span:nth-last-of-type(2)
            display: none

.XTR .trending
    .card
        .sectionName
            font-size: 0.75rem
            font-family: fonts.$fontRobotoSans
            font-weight: 300
        .title
            font-family: fonts.$fontRobotoSerif
            font-size: 0.94rem
            font-weight: 700
