
@use "../../../../../static/sass/library/fonts"

.storyCard--sponsored
    .storyCard
        &__suptitleLink
            font-family: fonts.$fontRobotoSerif
            font-size: 0.85em
        &__titleLink,
        &__leadLink
            font-family: fonts.$fontRobotoSerif
            font-size: 0.85em
        &__publicationDates
            display: none
