@use "include-media/dist/include-media"
@use "../library/breakpoints"
@use "../library/fonts"

.panorama--default
    .adsWrapper
        margin-top: -106px
        max-width: 100% !important
        @include include-media.media(">=full")
            margin-top: -126px
        @include include-media.media("<min")
            margin-top: -25px
    @include include-media.media("<min")
        margin: 0 0 0 0 !important
        border-top:  none
    border-top: 30px solid #d8d8d8
    width: 100% !important
    margin-top: 50px

.panorama--article
    margin: 0 -12px !important
    width: auto !important

.panorama__wrapper
    display: flex
    justify-content: center
    background-color: transparent
    position: relative
    &.panorama__wrapper--mobile
        height: 65vh !important
    &.panorama__wrapper--default
        height: 450px !important

.panorama__foregroundImg
    object-fit: contain
    pointer-events: none
    display: block
    max-width: 1024px
    max-height: 100%
    width: 100%
    height: auto
    position: absolute
    z-index: 1

.panorama__backgroundClipper
    min-width: initial !important
    pointer-events: none
    clip-path: inset(0 0)
    position: absolute
    width: 100%
    height: 100%
    border: 0
    top: 0

.panorama__backgroundWrapper
    position: fixed
    display: flex
    justify-content: center
    max-width: 100%
    max-height: 100%
    width: 100%
    height: 100%
    top: 0
    &.panorama__backgroundWrapper--homepage
        max-width: 1024px
        left: max(calc((100% - 1024px)/2),0px)
        height: 94vh
        top: 6vh
        & .panorama__backgroundElement--img
            min-height: 750px
    &.panorama__backgroundWrapper--default
        height: 94vh
        top: 6vh
    &.panorama__backgroundWrapper--article
        height: 91vh
        top: 9vh

.panorama__backgroundElement
    display: block
    max-width: 1024px
    max-height: 100%
    width: 100%
    height: auto
    border: 0 none

.panorama__backgroundElement--img
    object-fit: contain

.adsWrapper-sticky-top
    height: 1px

.adsWrapper--sticky
    position: sticky
    top: 0

.adsWrapper
    display: block
    width: 100%
    max-width: 1220px
    min-width: auto
    margin: 0 auto

    &.greyLineToppedBox
        border: none
        padding: 0
        display: block

        &.xtraAd
            @include include-media.media("<min")
                margin-top: 40px
            .isEmpty
                margin-bottom: 0
        .adSpotBlock
            &__slotWrapper
                border-top: 1px solid #dedede
                padding-top: 15px
    &.isEmpty
        display: none

    &.sticky
        min-width: auto
        max-width: 100%
        margin: 0 auto
        transition: background-color 1s
        background-color: #f0f0f0

        &.is-sticked
            position: sticky
            top: 0
            z-index: 1000
            transform: scaleY(1)
            transition: height 1s

        &.hide-animation
            transition: transform 1s ease-in-out
            transform: translateY(-100%)

    &.ads-loaded
        background-color: #f0f0f0

    .adSpotBlock
        text-align: center
        &:not(.adSpotBlock--filled):not(.adSpotBlock--force-rendered)
            opacity: 0
            margin: 0
            padding: 0
            height: 0
            pointer-events: none

        &__slotWrapper
            display: inline-block

        &__slotInner
            &::before
                position: relative
                display: block
                width: 100%
                margin: 0 auto
                background: #D8D8D8
                color: #686868
                content: "Publicité"
                text-align: center
                line-height: 2.5em
                padding: 0 0.5em
                font-family: fonts.$fontRobotoSerif
                font-size: 12px
                font-weight: 600
                text-transform: uppercase
                box-sizing: border-box
                @include include-media.media(">=min")
                    font-weight: 500

            &--xtra
                &::before
                    content: "Commandité"
                    background: #777777
                    color: #fff

            &--serie-xtra
                &::before
                    content: none

        &--pageView-mobile
            min-height: 425px

        &--empty,
        &--filled
            min-height: 0 !important

        &__slotInnerHeader
            background-color: #666666
            padding: 0.188rem 1rem
            box-sizing: border-box
            position: relative
            display: flex
            justify-content: space-between
            & div
                font-family: fonts.$fontRubrik
                font-size: 12px
                line-height: 2.5em
                color: white
            & div:first-child
                font-family: fonts.$fontRobotoSerif
                text-transform: uppercase
                font-weight: 600
