/*-------------------------------------------------------------------------------------------------------------------*/
/* NUANCIER DE COULEURS OFFICIEL
/* https://docs.google.com/spreadsheets/d/1LXDmBGw1Bw2Aq7A3joBb8MKXfgUgldaFTDXmzui1HT4/edit#gid:0
/*-------------------------------------------------------------------------------------------------------------------*/

/*------------------------------------------*/
/* STORIES CARDS
/*------------------------------------------*/

$STORY_CARD_TITLE: #000000
$STORY_CARD_TITLE_DARK_GREY: #2F2F2F
$STORY_CARD_SUPTITLE: #686868

$STORY_CARD_SUPTITLE_LIGHT: #D4D4D4

/*------------------------------------------*/
/* DATES DE PUBLICATION / MODIFICATION
/*------------------------------------------*/

$PUBLISHED_DATE: #8D8D8D
$MODIFIED_DATE: #404040

$PUBLISHED_DATE_LIGHT: #8D8D8D
$MODIFIED_DATE_LIGHT: #AAAAAA

/*------------------------------------------*/
/* TRAITEMENT DES SECTIONS PRINCIPALES
/*------------------------------------------*/

/*---- À la une ----*/

$UNE-6: #320209
$UNE-5: #4E0009
$UNE-4: #6B000C
$UNE-3: #810510
$UNE-2: #9F0814
$UNE-1: #C00015
$UNE: #E1001A
$UNE1: #E84255
$UNE2: #EE6E7D
$UNE3: #EF8F99
$UNE4: #F2AEB6
$UNE5: #F8C7CD
$UNE6: #FBE6E9

/*---- Actualités ----*/

$ACT-6: #14181D
$ACT-5: #1E2632
$ACT-4: #293646
$ACT-3: #35465D
$ACT-2: #405772
$ACT-1: #4D6889
$ACT: #5A79A0
$ACT1: #718DAD
$ACT2: #869EB9
$ACT3: #9EB0C5
$ACT4: #B4C3D3
$ACT5: #CCD6DF
$ACT6: #E4E9EE

/*---- Internationnal ----*/

$INT-6: #14181D
$INT-5: #1E2632
$INT-4: #293646
$INT-3: #35465D
$INT-2: #405772
$INT-1: #4D6889
$INT: #5A79A0
$INT1: #718DAD
$INT2: #869EB9
$INT3: #9EB0C5
$INT4: #B4C3D3
$INT5: #CCD6DF
$INT6: #E4E9EE

/*---- Affaires • Vos finances • Portfolio ----*/

$AFF-6: #0E1921
$AFF-5: #0D2939
$AFF-4: #113951
$AFF-3: #164969
$AFF-2: #1B5B83
$AFF-1: #206D9D
$AFF: #207FB7
$AFF1: #4093C3
$AFF2: #60A5CC
$AFF3: #7FB5D5
$AFF4: #9FC8E0
$AFF5: #BFDAEA
$AFF6: #DEEAF1

/*---- Arts ----*/

$ART-6: #0C1D17
$ART-5: #053022
$ART-4: #084631
$ART-3: #085C3F
$ART-2: #0E7552
$ART-1: #108A61
$ART: #14A574
$ART1: #38B48A
$ART2: #5BC09C
$ART3: #7ACEB2
$ART4: #9DDCC6
$ART5: #BEE9DB
$ART6: #DDF2EB

/*---- Auto ----*/

$AUT-6: #15181A
$AUT-5: #1E272D
$AUT-4: #2A373D
$AUT-3: #354753
$AUT-2: #415865
$AUT-1: #4E6A79
$AUT: #5B7C8E
$AUT1: #728E9E
$AUT2: #869FAD
$AUT3: #9FB1BA
$AUT4: #B5C3CC
$AUT5: #CED6DC
$AUT6: #E5E9EC

/*---- Débats ----*/

$DEB-6: #191716
$DEB-5: #282521
$DEB-4: #3A332D
$DEB-3: #4A423A
$DEB-2: #5D5248
$DEB-1: #706357
$DEB: #837466
$DEB1: #94877B
$DEB2: #A4998F
$DEB3: #B4ACA5
$DEB4: #C5C0B9
$DEB5: #D7D4D1
$DEB6: #E9E8E6

/*---- Dialogue ----*/

$DIA-6: #151907
$DIA-5: #1F2C02
$DIA-4: #2D3F00
$DIA-3: #3D5500
$DIA-2: #4C6A00
$DIA-1: #5B7F00
$DIA: #6B9500
$DIA1: #80A424
$DIA2: #95B348
$DIA3: #AAC26D
$DIA4: #C1D392
$DIA5: #D6E1B8
$DIA6: #E9EFDA

/*---- Dossier spécial ----*/

$DOS-6: #14181D
$DOS-5: #1E2632
$DOS-4: #293646
$DOS-3: #35465D
$DOS-2: #405772
$DOS-1: #4D6889
$DOS: #5A79A0
$DOS1: #718DAD
$DOS2: #869EB9
$DOS3: #9EB0C5
$DOS4: #B4C3D3
$DOS5: #CCD6DF
$DOS6: #E4E9EE

/*---- Gourmand ----*/

$GOU-6: #170A10
$GOU-5: #2C0C18
$GOU-4: #40081D
$GOU-3: #510721
$GOU-2: #620627
$GOU-1: #73052B
$GOU: #840132
$GOU1: #9E2E55
$GOU2: #B35476
$GOU3: #C27793
$GOU4: #D198AD
$GOU5: #DEBAC8
$GOU6: #F1DDE5

/*---- Maison ----*/

$MAI-6: #141915
$MAI-5: #1D281E
$MAI-4: #263928
$MAI-3: #314A36
$MAI-2: #3C5C41
$MAI-1: #486E4E
$MAI: #55815C
$MAI1: #6B9272
$MAI2: #6B9272
$MAI3: #9BB49F
$MAI4: #B3C6B5
$MAI5: #CBD8CC
$MAI6: #E3EAE4

/*---- Contexte ----*/

$CTXT-7: #000000
$CTXT-6: #1F1814
$CTXT-5: #372821
$CTXT-4: #50392E
$CTXT-3: #6b4C3B
$CTXT-2: #875F49
$CTXT-1: #A57358
$CTXT: #C38767
$CTXT1: #CD977B
$CTXT2: #D7A890
$CTXT3: #E0B9A6
$CTXT4: #E8CABB
$CTXT5: #F0DCD2
$CTXT6: #F8EDE8
$CTXT7: #FFFFFF

/*---- Neutre gris - couleurs par défaut ----*/

$DEF-7: #000
$DEF-6: #181818
$DEF-5: #262626
$DEF-4: #353535
$DEF-3: #444
$DEF-2: #555
$DEF-1: #666
$DEF: #777
$DEF1: #898989
$DEF2: #9B9B9B
$DEF3: #AEAEAE
$DEF4: #C1C1C1
$DEF5: #D4D4D4
$DEF6: #E8E8E8
$DEF7: #EEE
$DEF8: #DEDEDE

/*---- NOEL ----*/

$NOEL-6: #0C1B11
$NOEL-5: #022F15
$NOEL-4: #03431E
$NOEL-3: #005622
$NOEL-2: #006B2C
$NOEL-1: #008134
$NOEL:   #00993F
$NOEL1:  #24A75A
$NOEL2: #4CBA7A
$NOEL3: #71C895
$NOEL4: #95D8B1
$NOEL5: #B9E4CC
$NOEL6: #DEF3E6

/*---- Société ----*/

$SOC-6: #1E0E0F
$SOC-5: #3C1B1E
$SOC-4: #5A292D
$SOC-3: #78363B
$SOC-2: #96444A
$SOC-1: #B45159
$SOC: #D25F68
$SOC1: #D8767E
$SOC2: #DF8D93
$SOC3: #E5A4A9
$SOC4: #ECBABE
$SOC5: #F2D1D4
$SOC6: #F9E8E9

/*---- Sport ----*/

$SPO-6: #261C06
$SPO-5: #463000
$SPO-4: #684600
$SPO-3: #8E6100
$SPO-2: #B37A00
$SPO-1: #D99400
$SPO: #FFAF00
$SPO1: #FFBC25
$SPO2: #FFC749
$SPO3: #FFD356
$SPO4: #FFE094
$SPO5: #FFEBBA
$SPO6: #FFF5DB

/*---- Voyages ----*/

$VOY-6: #291409
$VOY-5: #4A2002
$VOY-4: #6B2C00
$VOY-3: #913D00
$VOY-2: #B44C00
$VOY-1: #D85A00
$VOY: #FF6C00
$VOY1: #FF8425
$VOY2: #FF9C4E
$VOY3: #FFB172
$VOY4: #FFC899
$VOY5: #FFDABB
$VOY6: #FFEDDC

/*---- Xtra & Suites ----*/

$XTR-6: #181818
$XTR-5: #262626
$XTR-4: #353535
$XTR-3: #444
$XTR-2: #555
$XTR-1: #666
$XTR: #777
$XTR1: #898989
$XTR2: #9B9B9B
$XTR3: #AEAEAE
$XTR4: #C1C1C1
$XTR5: #D4D4D4
$XTR6: #E8E8E8

/*------------------------------------------*/
/* TRAITEMENT PARTICULIER DES SOUS SECTIONS
/*------------------------------------------*/


/*---- Élections fédérales 2019 ----*/

$FED-6: #24272C
$FED-5: #2A2D33
$FED-4: #30343A
$FED-3: #363A42
$FED-2: #3C4149
$FED-1: #424750
$FED: #484E58
$FED1: #575C65
$FED2: #666B73
$FED3: #757A81
$FED4: #84888F
$FED5: #93979D
$FED6: #A3A6AB
$FED7: #DFDFE1

/*---- Élections provinciales ----*/

$PROV-6: #0F141D
$PROV-5: #122134
$PROV-4: #152E4C
$PROV-3: #1D3D64
$PROV-2: #234A7B
$PROV-1: #2A5994
$PROV: #3268AD
$PROV1: #4F7DB8
$PROV2: #6A92C2
$PROV3: #87A6CE
$PROV4: #A7BEDB
$PROV5: #C4D3E7
$PROV6: #E1E9F3

/*---- Techno ----*/

$TEC-6: #151721
$TEC-5: #222538
$TEC-4: #2E3451
$TEC-3: #3A4268
$TEC-2: #485282
$TEC-1: #57639C
$TEC: #6674B6
$TEC1: #7B87C0
$TEC2: #919BCA
$TEC3: #A5ADD2
$TEC4: #BAC0DD
$TEC5: #CFD4E7
$TEC6: #E7E8F2

/*---- Cinéma ----*/

$CIN-6: #1D151E
$CIN-5: #312034
$CIN-4: #472B4B
$CIN-3: #583B5B
$CIN-2: #724378
$CIN-1: #895190
$CIN: #A05FA8
$CIN1: #AD75B4
$CIN2: #BB8CC0
$CIN3: #C8A3CD
$CIN4: #D4B8D7
$CIN5: #E2CFE3
$CIN6: #EFE5F0

/*---- Video  & Photos ----*/

$VID-6: #181818
$VID-5: #262626
$VID-4: #353535
$VID-3: #444
$VID-2: #555
$VID-1: #666
$VID: #777
$VID1: #898989
$VID2: #9B9B9B
$VID3: #AEAEAE
$VID4: #C1C1C1
$VID5: #D4D4D4
$VID6: #E8E8E8

/*---- Press forte ----*/

$PPF-6: #BDA700
$PPF-5: #BDA700
$PPF-4: #BDA700
$PPF-3: #BDA700
$PPF-2: #BDA700
$PPF-1: #BDA700
$PPF: #BDA700
$PPF1: #D9C102
$PPF2: #D9C102
$PPF3: #D9C102
$PPF4: #D9C102
$PPF5: #D9C102
$PPF6: #D9C102

/*---- En photos LP+ et Paris Match ----*/
$PHO-3: #262626
$PHO-2: #353535
$PHO-1: #444444
$PHO: #444444
$PHO1: #555555
$PHO6: #AEAEAE

/*------------------------------------------*/
/* GESTION DES LISTES DE COULEURS
/*------------------------------------------*/

/* Listes de couleurs de bordure */
$navBorder: (ACT: $ACT, DOS: $DOS, INT: $INT, DEB: $DEB, DIA: $DIA, ART: $ART, SPO: $SPO, AFF: $AFF, AUT: $AUT, CIN: $CIN, MAI: $MAI, VOY: $VOY, GOU: $GOU,  NOEL: $NOEL, DEF: $DEF, XTR: $XTR, FED: $FED,  SOC: $SOC, PPF: $PPF1, VID: $VID, CTXT: $CTXT, PHO: $PHO)

/* Listes de couleurs de bg dans la navigation mobile */
$navBkg:    (ACT: $ACT-3, DOS: $DOS-3, INT: $INT-3, DEB: $DEB-3, DIA: $DIA-3, ART: $ART-3, SPO: $SPO-3, AFF: $AFF-3, AUT: $AUT-3, CIN: $CIN-3, MAI: $MAI-3, VOY: $VOY-3, GOU: $GOU-3, NOEL: $NOEL-3, DEF: $DEF-3, XTR: $XTR-3, FED: $FED-3, SOC: $SOC-3, PPF: $PPF, VID: $VID-3, CTXT: $CTXT-3, PHO: $PHO-3)

$navSectionBorder: (ACT: $ACT1, INT: $INT1, DEB: $DEB1, DIA: $DIA1, ART: $ART1, SPO: $SPO1, AFF: $AFF1, AUT: $AUT1, CIN: $CIN1, MAI: $MAI1, VOY: $VOY1, GOU: $GOU1, TEC: $TEC1, NOEL: $NOEL1, SOC: $SOC1, FED: $FED1, PPF: $PPF, VID: $VID1, CTXT: $CTXT1, PHO: $PHO1)

$capsuleBkg: (ACT: $ACT6, INT: $INT6, DEB: $DEB6, DIA: $DIA6, ART: $ART6, SPO: $SPO6, AFF: $AFF6, AUT: $AUT6, CIN: $CIN6, MAI: $MAI6, VOY: $VOY6, GOU: $GOU6, TEC: $TEC6, NOEL: $NOEL6, SOC: $SOC6, FED: $FED7, PPF: $PPF6, XTR: $XTR6, VID: $VID6, CTXT: $CTXT6, PHO: $PHO6)

//Couleurs de dossier
$dossierDarkBackground:  (ACT : $ACT-5, DOS: $DOS-5, INT: $INT-5, DEB: $DEB-5, DIA: $DIA-5, ART: $ART-5, SPO: $SPO-5, AFF: $AFF-5, AUT: $AUT-5, CIN: $CIN-5, MAI: $MAI-5, VOY: $VOY-5, GOU: $GOU-5, NOEL: $NOEL-5, DEF: $DEF-5, XTR: $XTR-5, FED: $FED-5, SOC: $SOC-5, PPF: $PPF, VID: $VID-5, CTXT: $CTXT-5, PHO: $PHO-3)
$dossierTabs:            (ACT : $ACT-6, DOS: $DOS-6, INT: $INT-6, DEB: $DEB-6, DIA: $DIA-6, ART: $ART-6, SPO: $SPO-6, AFF: $AFF-6, AUT: $AUT-6, CIN: $CIN-6, MAI: $MAI-6, VOY: $VOY-6, GOU: $GOU-6, NOEL: $NOEL-6, DEF: $DEF-6, XTR: $XTR-6, FED: $FED-6, SOC: $SOC-6, PPF: $PPF, VID: $VID-6, CTXT: $CTXT-6, PHO: $PHO-3)
$dossierDisabledTab:     (ACT : $ACT5,  DOS: $DOS5,  INT: $INT5,  DEB: $DEB5,  DIA: $DIA5,  ART: $ART5,  SPO: $SPO5,  AFF: $AFF5,  AUT: $AUT5,  CIN: $CIN5,  MAI: $MAI5,  VOY: $VOY5,  GOU: $GOU5,  NOEL: $NOEL5,  DEF: $DEF5,  XTR: $XTR5,  FED: $FED5,  SOC: $SOC5,  PPF: $PPF, VID: $VID5,  CTXT: $CTXT5,  PHO: $PHO6)
$dossierBadge:           (ACT : $ACT-2, DOS: $DOS-2, INT: $INT-2, DEB: $DEB-2, DIA: $DIA-2, ART: $ART-2, SPO: $SPO-2, AFF: $AFF-2, AUT: $AUT-2, CIN: $CIN-2, MAI: $MAI-2, VOY: $VOY-2, GOU: $GOU-2, NOEL: $NOEL-2, DEF: $DEF-2, XTR: $XTR-2, FED: $FED-2, SOC: $SOC-2, PPF: $PPF, VID: $VID-2, CTXT: $CTXT-2, PHO: $PHO)
$dossierLightBackground: (ACT : $ACT-1, DOS: $DOS-1, INT: $INT-1, DEB: $DEB-1, DIA: $DIA-1, ART: $ART-1, SPO: $SPO-1, AFF: $AFF-1, AUT: $AUT-1, CIN: $CIN-1, MAI: $MAI-1, VOY: $VOY-1, GOU: $GOU-1, NOEL: $NOEL-1, DEF: $DEF-1, XTR: $XTR-1, FED: $FED-1, SOC: $SOC-1, PPF: $PPF, VID: $VID-1, CTXT: $CTXT-1, PHO: $PHO6)
$dossierLead:            (ACT : $ACT4,  DOS: $DOS4,  INT: $INT4,  DEB: $DEB4,  DIA: $DIA4,  ART: $ART4,  SPO: $SPO4,  AFF: $AFF4,  AUT: $AUT4,  CIN: $CIN4,  MAI: $MAI4,  VOY: $VOY4,  GOU: $GOU4,  NOEL: $NOEL4,  DEF: $DEF4,  XTR: $XTR4,  FED: $FED4,  SOC: $SOC4,  PPF: $PPF, VID: $VID4,  CTXT: $CTXT4,  PHO: $PHO6)
