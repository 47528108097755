@use "../library/colors"
@use "../components/podcastVisual"
@use "include-media/dist/include-media"

// @todo simplify when legacy css will be removed
.trending, .trendingTabs
    li.trendingCard
        padding-top: 1rem
        border-top: solid 1px colors.$DEF5

        flex-basis: 30%

        a.trendingCard__visual
            padding-top: 0
            border-top: none
            position: relative
            .podcastEpisode__visual
                position: inherit

.trendingTabs
    li.trendingCard
        a.trendingCard__visual
            &:before
                color: white

.trending
    @include include-media.media("<min")
        @include podcastVisual.podcastVisual--storycard-small
    &--layout-small
        @include podcastVisual.podcastVisual--storycard-small
    li.trendingCard
        a.trendingCard__visual
            &:before
                color: black
