
@mixin podcast-sticker

    .podcastSticker
        position: absolute
        top: 0
        left: 0
        margin-left: Min(calc(3.2%), 0.5rem)
        margin-top: 0.5rem
        border-radius: 12px
        background-color: rgba(0, 0, 0, 0.6)
        width: 76px
        height: 1.5rem
        z-index: 2

        &::before
            position: absolute
            content: url("assets/balado_icon.inline.svg")
            top: 54%
            left: 18%
            transform: translate(-50%, -50%)

        &__duration
            font-size: 14px
            line-height: 15px
            font-family: Rubrik, Arial, sans-serif
            font-weight: 300
            display: block
            position: relative
            color: white
            top: 4px
            left: 33%

    .podcastSticker__badge
        margin-left: 1rem
        top: 0 !important
        margin-top: 2.4rem
        text-transform: initial

@mixin podcast-sticker-small
    .podcastSticker
        &::after
            transform: translate(-38%, -50%)

        &__duration
            font-size: 12px
            font-family: Rubrik, Arial, sans-serif
            font-weight: 300
            left: 34%

%podcast-sticker
    @include podcast-sticker

%podcast-sticker-small
    @include podcast-sticker-small
