@use "../../../../static/sass/library/fonts"
@use "../badge/badge"

.badgeCollection
    display: flex
    overflow: hidden
    column-gap: 0.3rem
    flex-wrap: wrap
    height: 1.5rem

.badgeCollection .badge:nth-child(n+3)
    display: none

/* pour Safari 14 et moins qui ne supporte pas column-gap en display flex */
@supports (-webkit-touch-callout: none) and (not (translate: none))
    .badgeCollection .badge:not(:last-child)
        margin-right: 0.3rem
/* pour Safari 14 et moins qui ne supporte pas column-gap en display flex */

.badgeCollection--sponsored
    .badge
        font-family: fonts.$fontRobotoSerif
