@use "include-media/dist/include-media"
@use "../../library/fonts"

.dates
    font-family: fonts.$fontRubrik
    font-size: 0.95rem
    display: table-cell
    vertical-align: middle
    color: #8d8d8d

    @include include-media.media("<=375px")
        font-size: 0.75rem
    span
        width: 100%
        padding: 2px 0

    .updated
        color: #404040

.articleBody
    .datewrapper .dates
        height: 44px

.publicationsDate
    &--type-publication
        color: #8d8d8d
    &--type-update
        color: #404040

    &--type-publication ~ &--type-update
        &::before
            content: "|"
            margin: 0 5px 0 2px
