@use "../library/breakpoints"
@use "include-media/dist/include-media"

.mostRecentList
    &__list
        list-style-type: none
        padding: 0
        margin: 0

    .slotWrapper
        margin-top: 1.125rem
        margin-bottom: 2.875rem

    &__loadMoreBtn
      margin: 0.625rem auto 0 auto
      display: block

    &--desktop
        .mostRecentList
            &__list
                padding: 0

    &--tpl-article
        padding: 0 0.75rem
        @include include-media.media(">=min")
            padding: 0

.XTR
  .mostRecentList__inner
        grid-template-rows: 3.6rem 50% 50%

.podcastList__loadMoreBtn
  margin: 30px auto 0 auto
  display: block
  text-decoration: none
  text-align: center
