@use "../../library/variables"


/* vidéo player*/
.video-js
    z-index: 1

/* overwrite : bouton Play du player VIDEO pour les pages web mobiles */
.video-js .vjs-big-play-button
    width: 7.5rem
    height: 7.5rem
    border-radius: 50%
    border: 0
    background-color: rgba(0,0,0,.5)
    margin-top: -3.5rem
    margin-left: -3.5rem

.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.vjs-big-play-button .vjs-icon-placeholder:before,
.vjs-icon-play:before
    background: url(variables.$img-path + "/video-icon.png")
    background-size: contain
    content: " "

.video-js .vjs-big-play-button:focus,
.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:hover
    background-color: rgba(102, 102, 102, 0.5)


.video-js .vjs-tech
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    width: 100%
    height: 100%
    margin: auto

