@use "include-media/dist/include-media"
@use "../../library/colors"
@use "../../library/fonts"

/*-------------------------------------------*/
/* Liste de vidéos (verticale et horizontale)*/
/*-------------------------------------------*/
=VideoListBorder
    @each $name, $color in colors.$navBorder
        &.#{$name}
            border-top: 4px solid  #{$color}
            padding: 2rem 0.5rem 1rem !important


=VideoListHover
    &:hover .visual img
        opacity: 0.9
    @each $name, $color in colors.$navBorder
        &.#{$name}:hover .title,
        &.#{$name}:hover .supTitle
            color: #{$color}


.videosList
    padding: 0 0.5rem 1rem
    .articleDetail
        padding-top: 8px

    .storyCard__badgeCollection
        padding-bottom: 0

    +VideoListBorder
    &__title
        font-family: fonts.$fontRubrik
        font-size: 1.4rem
        font-weight: bold
        text-transform: uppercase
        color: #232325
        padding: 0
        margin: 0 0 1.5rem 0

        @include include-media.media("<833px")
            font-size: 1.75rem

        .sectionName
            font-weight: 500
    ul
        margin: 0
        padding: 0
        list-style: none
    li
        margin: 0
        padding: 1rem 0 2em
        display: inline-block
        border-top: 1px solid colors.$DEF8
        width: 100%
        +VideoListHover
    img
        opacity: 1
    a
        text-decoration: none

        &:hover img
            opacity: 0.8
    p
        position: relative
        font-family: fonts.$fontVerlag
        margin-bottom: 1.2rem
        font-size: 1.4rem
        font-weight: bold
        color: #000
    a.visual
        display: block
        width: 100%
        padding-bottom: 66%
        position: relative
        img
            position: absolute
            object-fit: cover
            width: 100%
            height: 100%
    .dates
        font-size: 0.75rem
    .sectionNameBadge
        margin-top: 8px
    .supTitle
        font-size: 1rem
        color: #686868
        display: block
        font-weight: 300
        margin-top: 10px
        &:empty
            display: none
    .tag
        top: 1rem
        left: 0
    .title
        font-size: 1.4rem
        color: #2f2f2f
        display: block
        margin-top: 15px

.hasFeaturedAuthor
    font-family: fonts.$fontSentinel

.XTR .videosList
    .card
        .sectionName
            font-size: 0.75rem
            font-family: fonts.$fontRobotoSans
            font-weight: 300
        .title
            font-family: fonts.$fontRobotoSerif
            font-size: 0.94rem
            font-weight: 700
/*Mobile only*/
.main > .videosList

    padding: 2rem 0.5rem 2rem

    li
        .datewrapper
            margin-top: 1rem
    .videoList__title
        margin: 0 0 1.5rem 0
