
@mixin video-sticker

    .videoSticker
        position: absolute
        top: 0
        left: 0
        margin-left: Min(calc(3.2%), 0.5rem)
        margin-top: 0.5rem
        border-radius: 12px
        background-color: rgba(0, 0, 0, 0.6)
        width: 76px
        height: 1.5rem

        &::before,
        &::after
            position: absolute
            content: ""
            top: 50%
            left: 20%
            transform: translate(-50%, -50%)

        &::before
            border: 2px solid white
            border-radius: 50%
            width: 13px
            height: 13px

        &::after
            display: inline-block
            height: 0
            width: 0
            transform: translate(-40%, -50%)
            border-top: 3px solid transparent
            border-bottom: 3px solid transparent
            border-left: 5px solid white

        &__duration
            font-size: 15px
            line-height: 15px
            font-family: Rubrik, Arial, sans-serif
            font-weight: 300
            display: block
            position: relative
            color: white
            top: 4px
            left: 37%

    .videoSticker__badge
        margin-left: 1rem
        margin-top: 2.4rem

@mixin video-sticker-small
    .videoSticker
        width: 65px
        height: 20px

        &::before
            width: 11px
            height: 11px

        &::after
            transform: translate(-38%, -50%)

        &__duration
            top: 3px
            font-size: 13px
            line-height: 13px
            font-family: Rubrik, Arial, sans-serif
            font-weight: 300
            left: 36%

%video-sticker
    @include video-sticker

%video-sticker-small
    @include video-sticker-small
