@use "../../library/fonts"

.video-js
    .vjs-control-bar
        display: none
        flex-direction: column
        align-items: flex-end
        background-color: transparent
    .vjs-time-control, .vjs-progress-control, .vjs-volume-panel, .vjs-play-control
        display: none
    .vjs-big-play-button
        .vjs-control-text
            position: relative
            top: 2.44rem
            font-family: fonts.$fontRubrik
            font-size: .875rem
            font-weight: 300
    .vjs-fullscreen-control .vjs-icon-placeholder:before, .vjs-icon-fullscreen-enter:before
        content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 21 21'><g fill='white' fill-rule='nonzero'><path d='M16 .8h-4.3v2.9h3.7l-3.7 3.7 2 2 3.7-3.7v3.7h2.9V.8zM4.6 20.8h4.2V18H5.1l3.7-3.7-2-2L3.1 16v-3.7H.3v8.5z'/></g></svg>")
        margin-top: -1px

.video-js.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder:before, .vjs-icon-fullscreen-exit:before
        content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 20 20'><g fill='white' fill-rule='nonzero'><path d='M15.7 8.6H20V5.7h-3.7L20 2l-2-2-3.7 3.7V0h-2.9v8.6zM4.2 11.5H0v2.8h3.7L0 18l2 2 3.7-3.7V20h2.8v-8.5z'/></g></svg>")
        margin-top: -1px

.video-js.vjs-has-started
    .vjs-time-control, .vjs-progress-control, .vjs-volume-panel, .vjs-play-control
        display: inherit
    .vjs-control-bar
        display: flex
        flex-direction: inherit
        align-items: inherit
        background-color: inherit

.ima-non-fullscreen:before
    content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 21 21'><g fill='rgb(204, 204, 204)' fill-rule='nonzero'><path d='M16 .8h-4.3v2.9h3.7l-3.7 3.7 2 2 3.7-3.7v3.7h2.9V.8zM4.6 20.8h4.2V18H5.1l3.7-3.7-2-2L3.1 16v-3.7H.3v8.5z'/></g></svg>")
    margin-top: -1px

.ima-fullscreen:before
    content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 20 20'><g fill='rgb(204, 204, 204)' fill-rule='nonzero'><path d='M15.7 8.6H20V5.7h-3.7L20 2l-2-2-3.7 3.7V0h-2.9v8.6zM4.2 11.5H0v2.8h3.7L0 18l2 2 3.7-3.7V20h2.8v-8.5z'/></g></svg>")
    margin-top: -1px
