
/*---------------------------------------------*/
/*  Font family variables
/*---------------------------------------------*/

$fontVerlag: Verlag, Arial, sans-serif;
$fontCalluna: Calluna, Arial, sans-serif;
$fontRubrik: rubrik, Arial, sans-serif;
$fontArial: Arial, sans-serif;
$fontIcon: lp_web_icons, sans-serif;
$fontSentinel: Sentinel, Arial, sans-serif;
$fontRobotoSans: 'Roboto Sans', Arial, sans-serif;
$fontRobotoSerif: 'Roboto Serif', serif;
