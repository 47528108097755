@use "include-media/dist/include-media"
@use "../../library/fonts"
@use "../../library/variables"

/*---------------------------------------------*/
/* Component context
/*---------------------------------------------*/
.datewrapper
    position: relative
    overflow: hidden
    width: 100%
/*---------------------------------------------*/
/* Component
/*---------------------------------------------*/
.socialShare
    position: absolute
    width: 88px
    right: 0
    z-index: 10
    top: 0
    background: #f9f9f8
    text-align: right
    transition: width .5s ease-in-out
    transform-origin: right
    .share
        width:  calc(2.5em + 4px)
        height:  calc(2.5em + 4px)
        display: inline-block
        text-align: center
        vertical-align: top
        background: #f9f9f8
        img
            height: 18px
            margin-top: calc((2.5em - 18px) /2)
        &:hover
            cursor: pointer
    .buttons
        position: absolute
        top: 0
        list-style: none
        margin: 0
        padding: 0
        vertical-align: top
        right: -244px
        white-space: nowrap
        transition: right .5s ease-in-out
        transform-origin: right
        &--slidable
            z-index: -1
        li
            list-style: none
            display: inline-block
            vertical-align: top
            margin: 0 0.35em 0 0
            a
                width: 2.2rem
                height: 2.2rem
                border: #E2E2E2 1px solid
                display: inline-block
                text-align: center
                vertical-align: top
                background-color: #fff
                background-size: 24px auto
                background-position: center center
                background-repeat: no-repeat
    &.open
        width: 100%
        transition: width .5s ease-in-out
        transform-origin: right
        @include include-media.media("landscape")
            width: 88px
        .buttons
            right: 5em
    a.print
        width: 2.5em
        height: 2.5em
        float: right
        display: inline-block
        text-align: center
        vertical-align: top
        background-color: #f9f9f8
        background-size: 24px auto
        background-position: center center
        background-repeat: no-repeat
        background-image: url(variables.$img-path + "/print-icon-v2.png")
        &:hover
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADxSURBVHgB7VTLDYJAEB3AcKYEAtxpATqwA7YDLUU7gA7sAMrgAMmWwJnw8WEEOSwuEZGY8JLJAm++zOwQ7dgayhRh23bctq1Hc5woSpLnuS/iVFoZB5kCqog0TQtFXF3XDNkHtCQAHPAsyxIRZ1mWJzF/BTABZDoYIHPjGcBFP9iEvQu9Ttcc66CyG+e8eNj3Hx3H8ZqmiekLUFXV76tevcn/H0A6RcBV1/WLiCjL8ozjRAsDFGmachGBMS1kxr/7RVVV8fGNxWwfcRgz/fBuH/UveB4qky47KIeQSKQDPoCwd8tuzi5indCHWL0HO7bHHWj/W/u8yUwiAAAAAElFTkSuQmCC')
.buttons
    li.facebook a
        background-image: url(variables.$img-path + "/facebook-icon-v2.png")
        &:hover
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGwSURBVHgB7VXLbcIwGHaciHPEBOEl5VY2KGzABrBBxQZ0g25AmKBlAuipV3pD4lEfewsLQPr9yFFtx04IZz4psv3b/v63w9gDFfCqDnS73UGWZWN8AywjKd54nicwLg6Hw8ddCiLA9/25JC6DaDQaw+12K2ybvk3Y6/X6GL7wxawa4fl8njSbzd80Tb/NzYIHZDnnfMX+w6Ff8Lw1vCKiE+ZP0sOQ1vB4uNvtNur5wCQA+buLHHhFzGeqoN1u/0gF5AndbWl86oISiqHvIBfH43HGyhF1Op2JKtA8uFwu45LLy3xCOYK1K2m5CeJI8gU3Nl3WE075BOQjBzlDTjSOOgpuRRjHcZQvtCpCwjLzNJI+3O/3axsTCi7EfmrK0RetvC9MDwSrgSAIrB6rTaclGfH7RG1HqgyJH6EyqDc2eY1TkiGn79kkpz5R15oHaJSEFfECxXOZ2CtoTjJMJ5bzC6cCirVpQU0INGLiVHAVcD5lSknWAYybFvhMAcXZdvAGTG1PN7edJDeRQHpTBKuGoFLGM/Jm2wyctwAMLfm20A9H25ev6hKGJCC/K6QPXPEHzMiypNUbmvIAAAAASUVORK5CYII=')
    li.threads a
        background-image: url(variables.$img-path + "/threads-icon-v2.png")
        &:hover
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH4SURBVHgB7VXLdcJADFyM4RrSAd87dGAqSFJBTAWYCmIqCKkApwKgAqACfOTAZzvAucIDZwbWecaY2CRX9J6i9a6ikWZXQoi7JEgmyaFSqZgwT77v12GLatvNZDIu7Gi5XA7/BFCEaJo2wJKBPQSc0AbHADSOAbCfy+Va8/lcpgZQwcdYFqDdw+HgSCm9GB8TyzeohE8j6nNVQMugXC77tVqtnsLXpC/0XaSpgEH3+/2Mma9WKzuUbRt0HAFBj5vP5z8CWkqlkoOzV1TxGK1CiwIguEmLAE4oOOmyELhA5Xq73Y5xxLXQdb0H42Wz2WeRouQxyp2F9/BtV6tVQ1zSYifFu6AI/7RBltP1en2WDbNFhoY4XbwHH3LugcbGbwB6zF4BlGwioG0YW9ETFo/VoBcccUU0kSAIwEp6bCzoC8CbVOxJJgPQPnz64oYKzl5B0FDRZkJVPBsBVMK2ATKNqySuAqnGws83/+x2OyMUnM3F1zXEHVhMCk809gXFAUyh9eAJsosVaJ8PgIpvG+oGGZM+gD2kAmBWylrH9NE4ACHnXXUPE6w74dfDiuHzJdKK6oVUo4J00VdN3QtJHHbIuBN3eaQQPrwLi1XBp5kaIAJSFKdxzfkvg2P1EJjAEOOldW2S3vKDY4hTF1Mks4Z+LhaLibjLf+QbW+L5p5x/iwoAAAAASUVORK5CYII=')
    li.twitter a
        background-image: url(variables.$img-path + "/x-icon-v2.png")
        &:hover
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAI0SURBVHgB7ZQ/bNpQEMaNDcgbLkIiWw0IqaPZOpatI9mSqWTrVhg7BbaO6dZOkLET6lQxkU7tFrpRIYHHVkLgbhV/+7voIbmWsZMsWXKS9e58z9935+/e07RHe2hL+INisXjB8iawx0un05XRaOSGAZRKpdput+upsD2ZTFr+vO4PttutJIc3zInEFU8X11oulz3btq0gOO9swDsqfB8EFzP+K9Xz/mYymT7AdUITwmP83/h1VnOxWPT94LquD3CPpBjAT7UQM4IvIPGy2ewNKADP+LBpWVYBkNe8dyH5Id2Q+yY8PC6FVKW4MIKEdsB8ejQB6AJ4jW+JHvLL8B0BJ64e0ieSQJEIqAN4lQ68zWYzUCnRI1L8velRSdFAgFg7hmF4+G0FrkF6HAceS+BiAoRrr1arDnpcMDWXitzRbmFG3Ib5fO4+wXBPWCwI3vK7Tohf5vP5T7PZzNPu28HeAHyq3EYymXRkaiRA7EHY+bgTAUKfU3UNVyqVkeyZpikp0UPOwnnU90YcOEtLdXGK0B8hazBNL9DjTP26Bssfzsf3OxEALmfgnQrljvmAHr8EjLguetBNe69HLpfrSz6IE3oOyuWyQ5XXPvCWP18oFLoAv5IrhX1f1SGUca4weF5kB3LHsHzhkYm5nE6njeAero4rVXkN8J9yT+E/xz/iV30+2IHvAhOSIZVXtAOmupS9wSlqynnZB0l/JpVKSdVn4q/X66EWYePxeAhJlf2RY/posfYPxx4GlVkDITQAAAAASUVORK5CYII=')
    li.linkedIn a
        background-image: url(variables.$img-path + "/linkedin-icon-v2.png")
        &:hover
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD2SURBVHgB7VPRDYIwEG2L4Rs3qMAAjoAb6AS4gSOgG8gE4gaOgBvwDyQdgQUAX5OaNIVEBRL84CWX6z2O90qPErJgblCT8DwvbNv2imVlWdYhz/OMjAAzCYifkRwEr+v6RkaC9XAOmRAdAxzLDimjlKa2bR/Iv6MzZNd1I+yeq/JZlmXi+36A2YSSQK6apokZYyeUe4SDfvkj3GWvqbfqOFIqxQK1lilBzRFH1SIgLoX5+x3VH2AjoiiKVNfrG/IncKKJ64BRZHJDDOSXPXBMa8QGZaYZbCcxwP24CCEqhIBZrD1yJjGA7te3e5DBL1gM5jdYMD9eLYNZ8A7NIl8AAAAASUVORK5CYII=')
    li.bluesky a
        background-image: url(variables.$img-path + "/bluesky-icon.png")
        &:hover
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAF0SURBVHgB7VPLbYNAEB1w4EwJhI/EkVSQuAOnA7uCKBUYV2BSQewKklRgSiA3JD7adJAGgLyVIMKwu/jog5+0gt2ZeW95MxDdcFVwHGeP1bquewqCwL60zvf9kNfwWs4xjGkjgXZUG1VVtSP1pV7wiIdnqPnn1UmNCARbBfl2TD7GWIDRhSLdWSTIZyqBXxIj8jzvqd/A75WEfMJxN9xomsbatg1FVU3TfNi2fY9XCzl7kgAccgEU/pAc1mKxeEcOJ7BlSYh/D/dnFoHgQAqgmFuzVuWYphlLBfI8T/GJzyRu9hyYruvLLMvY8FATZcJrC0Ix1iO2Vrdk4Ja9YfYjUVATkNu4yYkUPkuQYhCWjLGzJk9+NJCvFeSM5KMcooerCZ9AICEJYFmCRn/J4nVdp7MCRVEkIPokscBRNmmIHWDPvEB3kw2NJonfnovzJYqh5lXEJRTgjULDHmDHsScwDGPTx/EVfJTTTmhXluWkuTdcD/4A+tqNy3S6b3cAAAAASUVORK5CYII=')
    li.copyLink a
        background-image: url(variables.$img-path + "/copylink-icon-v2.png")
        &:hover
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAG1SURBVHgB7VM7bsJAEF3bAdFhc4INUFCSjhK6lFCmijhBjhBxAidlKuhCF3ICXKZ0icRvy6QBp8WWnTeJFW3WFracIorEk1Yez+6+mX0zw9gJfw2NFUCz2exGUXSL1cWvp2narFQqjRaLhfh1gEajcQ3iScqWKJfLPTWIzoqTC2Q+wBrC9rC47/tj9c4Zy4lWq8UPh8MFCB0E4XK29XrdxMeGv10oAOfcDIKAEzm0vlNlgF+AnExTvZspEWTp67q+DcNwDpInvGKLjG0pOIffjgM56n0jg5w0n8KsKFsdy7Leq9XqK4LPKc5ntro+3O12guV5gVpQaH6+2Wyo60bUliBzZXIq9mq1clQeLQ85ZYb/GywXQUYki0q+Xq8naVyJADREpLeUeQ+692HbqQRHyAmJGkDbcZzZ9+Ds9/sX+C34OtJRmuArkE/ZESRegA6J4swGuDyT90gawzDa2PPQtq4QwmMZSJsDumTSMMnEpHnc7y5m4R4FzSQnJCSq1WqX7EuiDuw3LBqwx9hH9sNyuXRYTiQkUjvkx+GMgqYh8QIPwAA9w7RAyPGt0IRSq6o1OeF/4APsOMt6xsgAlwAAAABJRU5ErkJggg==')
    li.print
        display: none
        a
            background-image: url(variables.$img-path + "/print-icon-v2.png")
            &:hover
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADxSURBVHgB7VTLDYJAEB3AcKYEAtxpATqwA7YDLUU7gA7sAMrgAMmWwJnw8WEEOSwuEZGY8JLJAm++zOwQ7dgayhRh23bctq1Hc5woSpLnuS/iVFoZB5kCqog0TQtFXF3XDNkHtCQAHPAsyxIRZ1mWJzF/BTABZDoYIHPjGcBFP9iEvQu9Ttcc66CyG+e8eNj3Hx3H8ZqmiekLUFXV76tevcn/H0A6RcBV1/WLiCjL8ozjRAsDFGmachGBMS1kxr/7RVVV8fGNxWwfcRgz/fBuH/UveB4qky47KIeQSKQDPoCwd8tuzi5indCHWL0HO7bHHWj/W/u8yUwiAAAAAElFTkSuQmCC')

.mainContent__podcastFooter > .socialShare:before
    display: inline-block
    content: "Partager cette page"
    line-height: 40px
    margin-left: 0.75rem
    font-size: 1rem
    font-weight: 500
    font-family: fonts.$fontRubrik

.mainContent__podcastFooter > .socialShare:before
    margin: 0 1rem 0 0

.mainContent__podcastFooter .socialShare
    position: relative
    width: auto
    text-align: left
    margin: 33px 0 20px 0
    padding: 0 0.75rem
    & a.print
        display: none
    .buttons
        position: relative
        display: inline-block
        right: inherit
        li
            display: inline-block
    @include include-media.media(">min")
        padding: 0

.placeholderCopy
    display: none
    position: relative
    background-color: #000
    opacity: 0.8
    color: #fff
    text-align: center
    margin: 1rem
    top: 8px
    padding: 0.75rem 0.75rem
    font-size: 1rem
    font-family: fonts.$fontRubrik

    &::after
        content: '\2713\00A0Lien copié'

    &--layout-horizontal
        position: relative
