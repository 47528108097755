@use "sass:meta"
@use "include-media/dist/include-media"
@use "../../../../static/sass/library/breakpoints"
@use "../../../../static/sass/library/fonts"
@use "../../../../static/sass/components/videoSticker"
@use "../../../../static/sass/components/podcastSticker"
@use "../../../../static/sass/components/podcastVisual"
@use "../../../../static/sass/library/colors"
@use "./__badgeCollection/storyCard__badgeCollection"
@use "./--featuredAuthor/storyCard--featuredAuthor"
@use "./--sponsored/storyCard--sponsored"

.storyCard

    @include videoSticker.video-sticker
    @include podcastSticker.podcast-sticker
    @include podcastVisual.podcastVisual--storycard-medium
    padding-bottom: 0
    padding-top: 15px
    border-top: 1px solid #dedede
    font-family: fonts.$fontVerlag

    a
        text-decoration: none

    em
        font-style: italic

    sup
        line-height: 0.5em
        font-size: 0.75em
        vertical-align: super

    sub
        line-height: 0.5em
        font-size: 0.75em
        vertical-align: sub

    &__inner
        position: relative

    // cover

    &__cover
        position: relative
        margin-bottom: 0.5rem
        grid-area: cover
        width: 100%
        display: block
        padding-top: (2 / 3) * 100%

    &__image
        position: absolute
        top: 0
        height: auto
        width: 100%
        aspect-ratio: 3/2
        object-fit: cover

    &__picture
        height: 100%
        width: auto

    // suptitle

    &__suptitle
        display: block
        grid-area: suptitle
        color: #686868

        a
            color: inherit

    &__suptitleLink,
    &__suptitleLink:visited,
    &__suptitleLink:active,
    &__suptitleLink:focus
        text-decoration: none
        color: inherit

    // title

    &__title
        display: block
        grid-area: title
        color: black
        font-weight: bold

        a
            color: inherit

    &__titleLink,
    &__titleLink:visited,
    &__titleLink:active,
    &__titleLink:focus
        text-decoration: none
        color: inherit

    // lead
    &__lead
        grid-area: lead

        a
            color: inherit

    &__leadLink,
    &__leadLink:visited,
    &__leadLink:active,
    &__leadLink:focus
        text-decoration: none
        color: inherit

    // publicationDates

    &__publicationDates
        font-family: fonts.$fontRubrik
        font-size: 0.75rem
        line-height: 1.5em
        font-weight: normal
        color: #8d8d8d
        grid-area: date
        @include include-media.media(">=min")
            font-size: 0.625rem

    &__publishedDate
        color: colors.$PUBLISHED_DATE

    &__modifiedDate
        color: colors.$MODIFIED_DATE

    &__publishedDate ~ &__modifiedDate
        &::before
            content: "|"
            margin: 0 5px 0 2px

    // share

    &__share
        grid-area: share

    // Hover Section Color Modifier

    &:hover &__cover,
    &:hover &__blurredOverlay
        opacity: 0.9

    @each $name, $color in colors.$navBorder
        &--section-#{$name}:hover &__titleLink
            color: #{$color}

    &--section-AFF-NOM

        grid-template-rows: auto 0 !important

        .storyCard

            &__publicationDates
                display: none

            &__visual
                height: 6.88rem !important
                width: 90% !important
                background-color: white !important

                @include include-media.media(">=min")
                    height: 9.125rem !important
                    width: 13.75rem !important

            &__image
                height: 100% !important
                width: auto !important
                margin: 0 auto !important
                aspect-ratio: auto !important
