@use "../library/breakpoints"
@use "../library/fonts"

div.main
    .mainContent__story
        & > .socialShare

            &:before
                margin: 0 1rem 0 0

            .buttons
                z-index: auto
                li
                    a
                        width: 2.5rem
                        height: 2.5rem
