@use "../../library/fonts"

.contentCard
    text-align: center
    background-color: #f2f2f2
    display: none

    &--visible
        display: block

    &--withButton
        .contentCard
            &__btn
                font-family: fonts.$fontRubrik
                margin-top: 1.5rem
            &__link
                display: inline-block
                font-weight: 500
                font-size: 1rem
                padding: 0 4.785rem
                line-height: 38px
                height: 40px
                border-radius: 1.5rem
                text-align: center
                text-decoration: none
                cursor: pointer
                &--contribution
                    background-color: #ffe501
                    border: 1px solid #262626
                    color: #262626
                    font-weight: 600
                    &:hover
                        background-color: #ffe50190
                &--default
                    background-color: #f9f9f9
                    border: 1px solid #000
                    color: #000
                    &:hover
                        background-color: #f4f4f4
                &--login
                    background-color: #e1001a
                    color: #fff
                    &:hover
                        background-color: #e1001a90
            &--contribution
                background-color: #f2f2f2
            &--default,
            &--login
                background-color: #f9f9f9
