@use "sass:meta"
@use "../library/colors"
@use "../components/podcastVisual"

//@todo simplify selector when remove legacy css
div.main .mainStory

    .photoModule
        .photoModule__caption:last-child
            border-bottom: 1px solid colors.$DEF5

        .photoModule__caption
            &--credit
                font-size: 0.81rem

            &--description
                font-size: 1rem
                line-height: 1.63rem

        &__visual
            width: 100%

    .textModule
        &--type-teaser
            font-size: 1.63rem
            line-height: 2.38rem

        &--type-lead
            font-size: 1.63rem
            line-height: 2.38rem


    p.textModule--type-paragraph, .listModule li
        font-size: 1.25rem
        line-height: 1.88rem

    p.textModule--type-signature
        font-size: 1.2rem

    p.textModule--type-infraNote
        font-size: 1.2rem

    .authorModule
        &__name
            font-size: 1rem
            line-height: 0.88


    .audioModule
        &_wrapper
            background-color: colors.$DEF6

    //scribble live module
    #recentPostsList
        background: colors.$DEF6

    @include meta.load-css("modules/photo-gallery")