@use "../library/colors"

.videosList

    li.videoCard
        padding-top: 1rem
        border-top: solid 1px colors.$DEF5

        flex-basis: 30%

        .title
            font-size: 1.1rem
        a.videoCard__visual
            padding-top: 0
            border-top: none
            position: relative
            &:before
                color: white
