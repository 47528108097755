
.contentCard--articleAside1
    padding: 2.25rem 0

    .contentCard
        &__image
            width: 375px

    &.contentCard--imageOnly
        background-color: #f9f9f9
