@use "include-media/dist/include-media"
@use "../../library/colors"
@use "../../library/fonts"
@use "../../library/text"

=MostRecentBorder
    @each $name, $color in colors.$navBorder
        &.#{$name}
            border-top: 4px solid  #{$color}
=MostRecentHover
    @each $name, $color in colors.$navBorder
        &.#{$name}:hover .title,
        &.#{$name}:hover .subTitle
            color: #{$color}

    &.PPF:hover .title,
    &.PPF:hover .subTitle
        color: #BDA700

=LabelBg
    @each $name, $color in colors.$navBkg
        &.#{$name}
            .label
                background:  #{$color}
.podcast
    .mostRecent
        .sectionNameSuffix
            font-weight: 600
    .mostRecent__titleWrapper
        display: flex
        align-items: center
        justify-content: space-between
    .seasonDropdown
        margin: 1.5rem 0
        @include include-media.media(">min")
            margin-bottom: 20px
            margin-top: 35px
    .seasonDropdown select
        -moz-appearance: none
        -webkit-appearance: none
        -webkit-border-radius: 0
        appearance: none
        background:  #fff url("assets/arrow_down.inline.svg") no-repeat 93% 50%
        border: solid 1px #ececec
        border-radius: 0
        color: #000
        font-family: rubrik, Arial, sans-serif
        font-size: 0.875rem
        height: 36px
        padding: 0 30px 0 10px

.mostRecent

    &__dateBlock
        font-family: fonts.$fontRobotoSerif
        font-size: 1.375rem
        font-weight: 800
        text-transform: capitalize
        color: #232325
        background-color: #e8e8e8
        padding: 20px 0 20px 15px
        width: 100%
        margin-top: 28px
        margin-bottom: 15px
        &:first-of-type
            margin-top: 36px

        @include include-media.media(">600px")
            font-size: 1.75rem
            font-weight: 700
            margin-top: 1.375rem
            padding: 24px 0 24px 15px
            &:first-of-type
                margin-top: 0

    &__title
        font-family: fonts.$fontRubrik
        font-size: 1.75rem
        font-weight: bold
        text-transform: uppercase
        color: #232325
        padding: 0
        margin: 35px 0 24px
        line-height: 1.75rem
        .sectionName
            font-weight: 500
    article.authorStoryCard
        .authorStoryCard
            &__details
                margin-block-start: 0
                margin-block-end: 0

    article.card
        display: grid
        position: relative
        -ms-grid-columns: 39% 0.5rem 64%
        margin: 0
        grid-gap: 0
        color: #444
        width: 100%
        border-top: 1px solid colors.$DEF8
        grid-template-columns: 50% 50%
        grid-template-rows: auto auto
        @include include-media.media(">min")
            grid-template: auto auto / calc(30% + 15px) calc(70% - 15px)
        font-family: fonts.$fontVerlag
        padding: 0.9375rem 0 1.125rem 0


        +LabelBg
        a.visual
            -ms-grid-column: 1
            -ms-grid-column-span: 1
            grid-column: 1
            -ms-grid-row: 2
            -ms-grid-row-span: 2
            border: 0
            vertical-align: top
            margin: 0
            padding: 0
            overflow: hidden
            position: relative
            opacity: 0.95
            grid-row: 1
            @include include-media.media(">min")
                grid-row: 1 / 3
        .visualWrapper
            position: relative
            aspect-ratio: 3/2
            display: inline-block
            width: calc(100% - 15px)
            height: auto
            max-width: none
            max-height: none
            div
                position: relative
        img
            display: block
            position: relative
            top: initial
            width: 100%
            height: auto
            opacity: 1
            aspect-ratio: 1.5
            object-fit: cover
            object-position: top
        .tag,
        .label
            display: inline-block
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            max-width: 100%
            margin-bottom: 4px
            float: left

        .datewrapper
            -ms-grid-column: 2
            -ms-grid-column-span: 2
            -ms-grid-row: 2
            -ms-grid-row-span: 2
            grid-column: 1 / 3
            grid-row: 2
            margin-top: 1.2rem
            @include include-media.media(">min")
                grid-column: 2
                margin-top: initial
        .articleDetail
            font-size: 1rem
            line-height: 1
            position: relative
            vertical-align: top
            -ms-grid-column: 2
            -ms-grid-column-span: 2
            -ms-grid-row: 1
            -ms-grid-row-span: 1
            grid-column: 2
            grid-row: 1
            margin-top: 0
            font-weight: 400
            a
                text-decoration: none
                margin-top: 0
                position: relative
            &__lead
                @include text.line-clamp(2)
                font-size: 1.2rem
                line-height: 1.57rem
                color: #686868
                margin: 16px 0 20px 0
                display: none !important
                @include include-media.media(">min")
                    display: -webkit-box !important

            .articleDetail__lead.hasFeaturedAuthor
                font-family: fonts.$fontSentinel
                font-size: 1.21rem

            &__tagLabelRow
                position: absolute
                width: 100%
                height: 35px

        .mostRecentCard
            &__detail
                margin-block: 0
                &--layout-withTag
                    a
                        padding-top: calc(1rem + 35px)
                        &:before
                            top: 35px
            &__titleWrapper
                margin: 0
                font-size: 1em
        .title
            display: block
            margin: 0
            padding: 0
            font-size: 1rem
            font-weight: bold
            color: #000
            line-height: 1.14em
            margin-top: 0.4rem
            clear: both
            @include include-media.media(">min")
                font-size: 1.75rem
            &--vedette
                font-family: fonts.$fontSentinel
                font-size: 1.41rem
                font-weight: normal
        .title.hasFeaturedAuthor
            font-family: fonts.$fontSentinel
            font-size: 1.625rem
        .subTitle
            position: relative
            display: inline-block
            font-size: 0.875rem
            width: 100%
            color: #686868
            padding-top: 0.3em
            font-weight: 400
            @include include-media.media(">min")
                font-size: 1.125rem
            &--vedette
                font-size: 1.25rem
                font-family: fonts.$fontSentinel
                font-weight: normal
        .subTitle.hasFeaturedAuthor
            font-family: fonts.$fontSentinel
            font-size: 1.0625rem

        +MostRecentHover
        &:hover
            img
                opacity: 0.9

    +MostRecentBorder

    .dates
        font-size: 0.75rem
        height: auto

    .sectionNameBadge
        margin-bottom: 4px

.XTR .mostRecent,
.NOM .mostRecent
    &__title
        @include include-media.media(">small")
            margin-top: 0
    &.XTR
        padding-top: 1px
    border-top: none

    article.card.XTR
        .articleDetail
            a
                padding-top: 0
                &:before
                    display: none
            .label
                margin-right: 10px
                margin-top: 2px
    article.card
        font-family: fonts.$fontRobotoSerif

        .subTitle
            font-size: 0.6875rem !important
            font-weight: 400
            line-height: 1.36em
            color: #686868

            @include include-media.media(">600px")
                font-weight: 300
                font-size: 0.9375rem !important

        .title
            font-size: 0.8125rem !important
            font-weight: 600
            line-height: 1.36em
            margin-top: 8px

            @include include-media.media(">600px")
                font-weight: 500
                font-size: 1.375rem !important

        .mostRecentCard__lead
            font-family: fonts.$fontRobotoSans
            font-size: 0.6875rem

            @include include-media.media(">600px")
                font-size: 1rem
                line-height: 1.38em
                font-weight: 300
                margin-top: 15px
                color: #686868

.topTitle
    padding: 0 0 1.875rem !important
    margin: 0 !important
    @include include-media.media("<=600px")
        padding: 1.5rem 0 1.5rem !important

.NOM .main .mostRecent.mostRecentList

    .topTitle
        padding: 0 0 2.125rem !important
        @include include-media.media("<=600px")
            padding: 1.5rem 0 0 !important

    .mostRecentCard

        &__lead
            font-family: fonts.$fontRobotoSerif
            display: block
            margin-top: 0.375rem
            line-height: 1.3
            @include text.line-clamp(2)
            @include include-media.media(">600px")
                font-size: 0.8125rem
                line-height: 1.54em

.VID .main .mostRecentList.mostRecent
    .mostRecentCard
        &__lead
            display: none
        &__detail
            margin-bottom: 0.75rem
            margin-top: 0.5rem

            @include include-media.media(">small")
                margin-top: initial

.mostRecent__title .description
    font-weight: initial
    font-size: 1rem
    text-transform: initial
    color: #676767
    margin-bottom: .5rem

.pagination__wrapper
    display: flex
    justify-content: center
    align-items: center
    margin-top: 10px
    max-width: 740px

.pagination__wrapper:has(.pagination__button--first)
    margin-left: -44px


.pagination__info
    margin: 0 26px
    font-size: 13px
    font-family: "Rubrik", serif


.pagination__button
    height: 44px
    width: 88px
    font-size: 30px
    background-color: white
    border-radius: 0
    border: solid 1px #{colors.$DEF8}
    padding: 0
    cursor: pointer
    outline: inherit
    font-weight: lighter
    display: block
    text-decoration: none
    text-align: center
    color: black
    line-height: 42px
    font-family: fonts.$fontRubrik


.pagination__button--disabled
    cursor: not-allowed
    pointer-events: none
    opacity: 0.5

.pagination__button--first
    width: 44px
