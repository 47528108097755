@use "include-media/dist/include-media"
@use "../../library/colors"
@use "../../library/fonts"
@use "../../library/variables"

/*  Boite statique qu'est-ce qu'un Xtra ou Quest-ce que Suite
.main article .whatIsXtra
    background-color: colors.$XTR6
    max-height: 38px
    overflow: hidden
    margin: variables.$module-spacing 0 calc(variables.$module-spacing*2.5) 0
    padding: 0.688em 0.938em 0.688em 0.938em
    color: colors.$XTR
    font-family: fonts.$fontRobotoSerif
    cursor: pointer
    @include include-media.media(">=min")
        padding: 0.875em 0.938em 0.813em 0.938em
        max-height: 44px

    &__header
        margin: 0
        display: flex
        justify-content: space-between

    &__title
        font-size: 0.875rem

    &__image
        transform: rotate(180deg)
        filter: invert(0.7)
        object-fit: contain

    &__text
        font-family: fonts.$fontRobotoSans
        font-size: 0.9375rem
        font-weight: 400
        line-height: 1.33em
        height: 0
        overflow: hidden
        margin-bottom: 0
        @include include-media.media(">=min")
            font-size: 0.9375rem
            font-weight: 300
    &.open
        -webkit-transition: max-height 1s ease-in
        transition: max-height 0.75s ease-in
        max-height: 200px
        padding-bottom: 0.63em
        @include include-media.media(">=min")
            max-height: 114px
        .whatIsXtra
            &__image
                transform: rotate(0deg)
            &__text
                padding: 1.563em 0 0.063em 0
                margin: 0
                height: auto
                @include include-media.media(">=min")
                    padding-bottom: 1.25em
