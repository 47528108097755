@use "include-media/dist/include-media"
@use "../../library/colors"
@use "../../library/fonts"
@use "../../library/variables"

.quote
    width: 100%
    margin: calc(variables.$module-spacing*2) 0 calc(variables.$module-spacing*2) 0
    padding: 0 0 0 1rem
    border-left: 1px solid colors.$DEF6
    .quoteText
        font-family: fonts.$fontVerlag
        font-weight: 700
        color: #131313
        font-size: 1.375rem
        margin: 0
        padding: 0
        line-height: 1.5em

        &:first-child:before
            content: "\00ab\00A0"
            display: inline

        &:last-child:after
            content: "\00A0\00bb"
            display: inline

    .quoteSource
        font-family: fonts.$fontVerlag
        font-weight: 500
        color: #7e7e7e
        font-size: 1.25rem
        margin: 1em 0 0 0 
        padding: 0
        &:before
            content: "\2014\00A0"
            display: inline

.XTR div.main
    article .quote
        .quoteText
            font-family: fonts.$fontRobotoSerif
            font-weight: 700
            font-size: 1.125rem
            @include include-media.media(">=min")
                font-size: 1.1875rem
                font-weight: 600
                line-height: 1.58em

        .quoteSource
            font-family: fonts.$fontRobotoSans
            font-size: 1.1875rem
            font-weight: 400
            @include include-media.media(">=min")
                font-size: 1.25rem
                font-weight: 300

.capsuleModule
    .quote
        border-color: colors.$DEF4
