@use "include-media/dist/include-media"

.lightBoxVideo
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(0,0,0,.7)
    z-index: 1001
    margin: auto auto
    display: none
    &--display
        display: block
        .videoModule
            max-height: 100vh
            max-width: calc(100vw - 20px)
            background-color: black
            aspect-ratio: 16/9
            overflow: hidden
            margin: 100px auto 0 auto
            @media (orientation: landscape)
                margin-top: 0
                max-height: 90vh
                max-width: 90vw
                top: 50%
                transform: translateY(-50%)
            @include include-media.media(">=min")
                margin-top: 0
                max-width: 1014px
                border: 5px solid white
                top: 50%
                transform: translateY(-50%)
            position: relative
            background-color: black
            border: 2px solid white
            .video-js
                position: static
                height: auto
            .closeButton
                display: inline-block
        .videoModule:has(> .video-js.vjs-ad-playing)
            .closeButton
                display: none
