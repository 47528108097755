@use "include-media/dist/include-media"
@use "../library/breakpoints"
@use "../library/variables"
@use "../library/fonts"
@use "../library/colors"

$desktopHeaderHeight: min(100vh - 350px, 70vh)
$desktopHeaderHeightWithSubSections: min(100vh - 390px, 70vh)
$captionInitialHeight: 2.25em

.enhancedStorytellingHeader
    display: flex
    flex-direction: column
    justify-content: end
    align-items: center
    margin: 44px 0 2em 0
    background-color: #2D2D2D
    overflow: hidden
    position: relative
    min-height: 7.5rem

    @include include-media.media(">=min")
        margin: 0
        padding: 0
        height: $desktopHeaderHeight

        .enhancedStorytellingHeader--subSection
            height: $desktopHeaderHeightWithSubSections

.enhancedStorytellingHeader:has(.lightBoxVideo)
    cursor: pointer

.enhancedStorytellingHeader__visualWrapper
    margin: 0 -0.75em 0 -0.75em
    position: relative
    top: 0
    width: 100%
    overflow: hidden
    @include include-media.media(">=min")
        height: calc($desktopHeaderHeight - $captionInitialHeight)
        position: absolute
        .enhancedStorytellingHeader--subSection &
            height: calc($desktopHeaderHeightWithSubSections - $captionInitialHeight)

    & .vjs-poster img, & .vjs-tech
        object-fit: cover
        object-position: 50% 50%

    & .video-js
        position: initial

.enhancedStorytellingHeader__visualWrapper::before
    content: ""
    position: absolute
    top: 50%
    left: 0
    width: 100%
    height: 51%
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #2D2D2D)
    z-index: 1

.enhancedStorytellingHeader__visual
    width: 100%
    object-fit: cover
    display: block
    max-width: 100%
    max-height: 100%
    height: 100%
    aspect-ratio: 1.5
    @include include-media.media(">=min")
        aspect-ratio: initial

.enhancedStorytellingHeader__focalPoint
    background-color: #fff
    border: 0
    display: none
    mix-blend-mode: difference
    position: absolute

.enhancedStorytellingHeader__focalPoint--vertical
    height: 100%
    top: 0
    width: 1px

.enhancedStorytellingHeader__focalPoint--horizontal
    height: 1px
    left: 0
    width: 100%

.enhancedStorytellingHeader__content
    margin: -0.75em 0 0 0
    padding: 0 0.75em 2em 0.75em
    font-family: fonts.$fontVerlag
    text-align: center
    width: 100%
    max-width: variables.$content-width-sm
    color: white
    display: block
    z-index: 1

    @include include-media.media(">=min")
        margin: 0
        padding: 0 0 1.5em 0

.enhancedStorytellingHeader__title
    text-transform: uppercase
    display: block
    font-size: 1.5rem
    font-weight: 800
    padding-right: 0.25em
    overflow: hidden
    @include include-media.media(">=min")
        font-size: 2.4375rem

.enhancedStorytellingHeader__supTitle
    display: block
    font-size: 1rem
    font-weight: 500
    padding-right: 0.25em
    margin-bottom: 0.625em
    overflow: hidden
    @include include-media.media(">=min")
        font-size: 1.3125rem

.enhancedStorytellingHeader__caption
    z-index: 1
    background-color: #2D2D2D
    position: absolute
    color: #d9d9d9
    margin: 0 -0.75em 0 -0.75em
    padding: 0.75em
    width: 100%
    transition: transform .25s ease-in-out
    transform: translateY(calc(100% - $captionInitialHeight))
    @include include-media.media(">=min")
        padding-right: 1em

    &--opened
        transform: translateY(0)

        & .caption__icon
            transform: rotate(180deg)

.caption
    &__credit
        font-family: fonts.$fontVerlag
        font-weight: 500
        text-transform: uppercase
        margin: 0
        overflow: hidden
        display: -webkit-box
        -webkit-line-clamp: 1
        -webkit-box-orient: vertical

    &__icon
        display: block
        height: 0.7em

    &__creditWrapper
        display: flex
        justify-content: space-between
        margin-bottom: 0.66em
        font-size: 0.75rem
        height: 1.325em
        @include include-media.media(">=min")
            justify-content: flex-end
            margin-bottom: 1em

    &__iconWrapper
        margin: 0 0 0 0.563em
        display: flex
        align-items: center
        cursor: pointer

    &__description
        font-size: 0.8125rem
        margin: 0
        font-family: fonts.$fontVerlag
        @include include-media.media(">=min")
            text-align: end

.XTR .caption
    &__credit
        font-family: fonts.$fontRobotoSerif

    &__description
        font-family: fonts.$fontRobotoSans

.XTR .enhancedStorytellingHeader__content
    font-family: fonts.$fontRobotoSerif
