@use "include-media/dist/include-media"

.closeButton
    border: none
    width: 16px
    height: 14px
    background-image: url("assets/close-button-white.inline.svg")
    background-size: 16px 14px
    background-repeat: no-repeat
    background-color: transparent
    background-position: center center
    padding: 0
    margin: 0
    cursor: pointer
    position: absolute
    right: 10px
    top: 10px
    z-index: 2
    display: none
    @include include-media.media(">=min")
        width: 28px
        height: 24px
        right: 25px
        top: 25px
        background-size: 28px 24px
