
@use "../../../../static/sass/library/fonts"
@use "../../../../static/sass/library/breakpoints"
@use "include-media/dist/include-media"

@use "../storyList/storyList"

.relatedStories

    &__header
        font-family: fonts.$fontRubrik
        margin-bottom: 1.875rem
        margin-top: 1.25rem

    &__headerSuptitle
        display: block
        font-size: 0.8125rem
        color: #686868
        font-weight: 400
        margin-bottom: 0.25rem
        @include include-media.media(">=min")
            font-size: 1rem
            margin-bottom: 0.6875rem

    &__headerTitle
        font-size: 1.5rem
        font-weight: 700
        text-transform: uppercase
        color: #232325
        @include include-media.media(">=min")
            font-size: 1.75rem

    @include include-media.media(">=min")
        .storyCard__badgeCollection
            padding-top: 8px
