
.contentCard--articleAside2
    padding: 0
    margin: 0
    .contentCard
        &__image
            width: 300px

    &.contentCard--imageOnly
        background-color: #f9f9f9
        .contentCard
            &__image,
            &__link
                display: block
                width: 300px
                height: 600px
