@use "include-media/dist/include-media"
@use "../library/breakpoints"
@use "../library/colors"
@use "../library/fonts"

$a-tags: 'a, a:active, a:hover, a:visited'
$a-tags-hover: 'a:active, a:hover'

.contactUsXtra
    background-color: #e8e8e8
    border: 1px solid #979797
    color: #fff
    padding: 40px 21px
    margin-bottom: 40px
    .contactUsXtra__link
        margin-top: 0
        margin-bottom: 20px
        font-family: fonts.$fontRobotoSerif
        font-weight: 600
        font-size: 1.25rem
        @include include-media.media(">600px")
            font-size: 1.25rem
            font-weight: 500

        #{$a-tags}
            color: colors.$XTR
            text-decoration: none
        #{$a-tags-hover}
            text-decoration: underline

    p
        font-family: fonts.$fontRobotoSans
        font-weight: 400
        font-size: 0.9375rem
        color: #584d48
        line-height: 1.4em
        letter-spacing: 0.01em
        margin-bottom: 0
        @include include-media.media(">600px")
            font-size: 0.9375rem
            font-weight: 300
