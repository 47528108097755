@use "include-media/dist/include-media"
@use "../library/breakpoints"
@use "../library/variables"
@use "../library/fonts"
@use "../library/colors"

@mixin podcastVisual--base
  .podcastEpisode__visual
    position: relative
    background-color: #807f7f
    overflow: hidden
    margin-top: 0
    top: 0
    height: auto
    width: 100%
    aspect-ratio: 3/2
    &:before
      content: ""
      background-image: var(--balado-visual-src)
      filter: blur(25px)
      position: absolute
      width: 100%
      height: 100%
      object-fit: cover
      background-repeat: no-repeat
      background-size: 110%
      background-position: center
    .podcastEpisode__image
      aspect-ratio: 1
      margin: auto 0
      display: block
      object-fit: cover
      border-radius: 10%
      filter: drop-shadow(0 4px 2px rgba(13, 13, 13, 0.32))
      z-index: 1
    .podcastEpisode__imageWrapper
      display: flex
      height: 100%
      width: 100%
      &:before, &:after
        height: 100%
        z-index: 1
        background-repeat: no-repeat
        background-position-y: center
        background-size: contain
      &:before
        background-image: url(variables.$img-path + "/podcast/podcast-wave.svg")
        margin-left: auto
        content: ""
      &:after
        background-image: url(variables.$img-path + "/podcast/podcast-wave.svg")
        transform: rotateY(180deg)
        margin-right: auto
        content: ""
  .podcastEpisode__visualWrapper .podcastEpisode__visual
    border-radius: 10%
    aspect-ratio: 1
    height: initial

@mixin podcastVisual--podcastPage
  @include podcastVisual--base
  .podcastEpisode__visual
    .podcastEpisode__imageWrapper
      &:before, &:after
        width: 23%
    .podcastEpisode__image
      width: 45%
      opacity: 1
      height: initial
      aspect-ratio: 1 !important

@mixin podcastVisual--podcastEpisode
  @include podcastVisual--base
  .podcastEpisode__visualWrapper
    .podcastEpisode__visual
      margin-top: 0
      width: 100%
      height: 100%
      .podcastEpisode__image
        width: 70%
        margin: auto
      .podcastEpisode__imageWrapper
        &:before, &:after
          position: absolute
          width: 40%
        &:before
          left: -25%
        &:after
          left: 85%

@mixin podcastVisual--storycard-large
  @include podcastVisual--base
  @include podcastVisual--storycard-medium
  @include include-media.media(">min")
    .podcastEpisode__visual
        aspect-ratio: 3/2
        &:hover
          opacity: 0.9
        .podcastEpisode__imageWrapper
          aspect-ratio: 3/2
          &:before, &:after
            width: 18%
          .podcastEpisode__image
            width: 33%

@mixin podcastVisual--storycard-medium
  @include podcastVisual--base
  .podcastEpisode__visual
    &:hover
      opacity: 0.9
    .podcastEpisode__imageWrapper
      &:before, &:after
        width: 26.5%
    .podcastEpisode__image
      width: 45%
      opacity: 1
      height: initial
      aspect-ratio: 1 !important

@mixin podcastVisual--storycard-small
  @include podcastVisual--base
  .podcastEpisode__visual
    width: 100% !important
    height: auto
    aspect-ratio: 1
    object-fit: cover
    &:hover
      opacity: 0.9
    .podcastEpisode__image
      width: 100%
      margin: 0
      aspect-ratio: 1
      object-fit: cover
      position: absolute
      border-radius: 0
      filter: none
      z-index: 0

    .podcastEpisode__imageWrapper
      &:before, &:after
        background: none

  .podcastSticker
    top: calc(100% - (1.65rem))
    left: max(0px, calc(50% - (76px / 2)))
    margin-top: 0
    margin-left: 0
    max-width: 100%

