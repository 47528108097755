@use "../../library/colors"
@use "../../library/fonts"
@use "../../components/podcastVisual"

=xtraHover
    &:hover .visual img
        opacity: 0.9
    @each $name, $color in colors.$navBorder
        &.#{$name}:hover .title,
        &.#{$name}:hover .supTitle
            color: #{$color}

.xtra

    margin-top: 15px
    margin-bottom: 40px
    ol
        display: block
        margin: 0
        padding: 0
        &:after
            height: 0
            width: 50%
            content: ""
    a.visual
        position: relative
        display: inline-block
        vertical-align: top
        padding: 0
        border: 0
        margin-bottom: 0
        margin-right: 0.75rem
        width: 80px
        height: 80px
        &:before
            color: #000
            bottom: auto
            left: 91px
            top: 0
            position: absolute
            font-family: fonts.$fontVerlag
            font-weight: bold
            font-size: 40px
            line-height: 30px
            display: block
            z-index: 10
        img
            object-fit: cover
            height: 80px
            max-width: 80px

    .articleDetail
        position: relative
        white-space: normal
        display: inline-block
        vertical-align: top
        width: calc(100% - 80px - 0.75rem)
        &:before
            width: 55px
            height: 8px
            position: absolute
            top: 0
            display: inline-block
            content: " "
            left: 52px
        a
            color: inherit
            text-decoration: none
        p
            margin: 0

    .title
        font-family: fonts.$fontRobotoSerif
        font-size: 1rem
        font-weight: 700
        color: #000
        line-height: 1.38rem
        display: block
    .supTitle
        display: block
        margin-top: -0.15rem
        margin-bottom: 0.7em
        font-size: 0.88rem
        font-family: fonts.$fontRobotoSans
        font-weight: 300
        line-height: 1.13rem
        color: #686868
        width: calc(133px + 0.75rem)
        min-height: 1rem
        &:empty
            display: none
    li.card
        display: block
        position: relative
        white-space: nowrap
        margin: 0 0 0.5rem
        padding: 1em 0
        border-top: 1px solid colors.$DEF8
        font-family: fonts.$fontVerlag
        +xtraHover
        @include podcastVisual.podcastVisual--storycard-small

    .adsWrapper
        .adSpotBlock__slotWrapper
            margin-top: 30px
            margin-bottom: 17px

.whatIsAnXtra
    position: absolute
    top: -10px
    left: 15px
    right: 15px
    font-family: fonts.$fontRubrik
    font-size: 0.875rem
    font-weight: 300
    line-height: 1.43
    display: none
    color: #fff
    opacity: 0.9
    background-color: #181818
    padding: 15px
    z-index: 1

    &__close
        background-image: url("assets/ico_close_b.inline.svg")
        position: absolute
        right: 10px
        top: 10px
        width: 16px
        height: 16px
        background-size: 16px
        background-repeat: no-repeat
        background-position: 50%
        cursor: pointer
        padding: 15px

        &:hover
            background-color: #414141

    &__title
        display: block
        color: #dbdbdb
        padding-bottom: 15px

    &__displayed
        display: block

