
@use "../../../../../static/sass/library/breakpoints"
@use "include-media/dist/include-media"

.storyList--related

    &.storyList
        display: flex
        flex-direction: column
        list-style-type: none
        margin: 0
        padding: 0
        overflow: hidden

        @include include-media.media(">=min")
            flex-direction: row

    .storyList
        &__item
            margin-bottom: 1.125rem
            @include include-media.media(">=min")
                margin-right: 2rem

        &__item:last-child
            @include include-media.media(">=min")
                margin-right: 0
