.logout
    padding-top: 145px
    padding-bottom: 70px

    &__check
        width: 100%
        height: 65px
        display: inline-block
        background-size: 65px
        background-position: 50% center
        background-repeat: no-repeat
        background-image: url("assets/icn_success.inline.svg")
        position: relative
        cursor: pointer

    &__dconn
        font-family: Rubrik
        font-size: 18px
        font-weight: 600
        font-stretch: normal
        font-style: normal
        line-height: normal
        letter-spacing: normal
        text-align: center
        color: #000
        padding-top: 15px

    &__home
        font-family: Rubrik
        font-size: 14px
        font-weight: normal
        font-stretch: normal
        font-style: normal
        line-height: 1.57
        letter-spacing: normal
        text-align: center
        color: #181818
        padding-top: 50px

        a, a:hover
            color: #181818
