
@use "include-media/dist/include-media"
@use "../../../../../static/sass/components/podcastVisual"
@use "../../../../../static/sass/library/breakpoints"

.storyCard--related

    display: grid
    column-gap: 0.9375rem
    grid-template-columns: 26.6vw auto
    grid-template-rows: auto auto 1fr auto
    grid-template-areas: "cover badge badge" "cover suptitle suptitle" "cover title title" "date date share"
    @include include-media.media(">=min")
        display: flex
        flex-direction: column
        width: 14.0625rem
    @include include-media.media("<min")
        @include podcastVisual.podcastVisual--storycard-small
        grid-template-columns: 100px auto

    .storyCard

        &__cover
            margin: 0
            padding: 0
            display: flex
            justify-content: center
            overflow: hidden
            height: 26.6vw

            @include include-media.media(">=min")
                height: 9.375rem
                width: auto

            @include include-media.media("<min")
                width: 6.25rem
                height: 6.25rem

        &__image
            position: relative
            @include include-media.media("<min")
                height: 100%
                width: auto
                transform: translateX(-50%)
                left: 50%
                margin: 0 !important
                aspect-ratio: 1
                object-fit: cover


        &__suptitle
            font-size: 1rem

        &__title
            font-size: 1.125rem
            margin-top: 0.4rem
            margin-bottom: 0

            @include include-media.media(">500px")
                font-size: 1.375rem

            @include include-media.media(">600px")
                font-size: 1.5rem

            @include include-media.media(">=min")
                margin-top: 0.5rem
                margin-bottom: 0
                font-size: 1.375rem

        &__publicationDates
            margin-top: 1.125rem

            @include include-media.media(">=min")
                margin-top: 1rem

    &.storyCard--section-XTR

        background-color: #e8e8e8
        border-color: #dedede
        padding-bottom: 0.75rem

        @include include-media.media(">=min")
            background: linear-gradient(rgba(0, 0, 0, 0) 15px, #e8e8e8 15px)

        .storyCard
            &__suptitle,
            &__title,
            &__lead
                padding-left: 0.4375rem
                padding-right: 0.4375rem
            &__badgeCollection
                padding-left: 0.3125rem
            &__suptitle
                font-size: 0.875rem
            &__title
                font-size: 1.125rem
            &__publicationDates
                display: none
