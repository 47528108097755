@use "sass:meta"
@use "../../library/fonts"


.button
    display: inline-block
    font-family: fonts.$fontRubrik
    color: black
    border: 1px solid black
    padding: 15px
    border-radius: 25px
    width: 300px
    max-width: 100%
    font-size: 16px
    cursor: pointer

@include meta.load-css("--theme-dark/button--theme-dark")
@include meta.load-css("--theme-light/button--theme-light")
