
@use "../library/breakpoints"
@use "videoSticker"
@use "podcastSticker"
@use "include-media/dist/include-media"

.pageBlock

    // ===============================================================
    // Home Page     =================================================
    // ===============================================================

    &--emplacement-homePageHeaderAd
        .adSpotBlock
            margin-top: 0
            padding-bottom: 30px
            @include include-media.media(">=full")
                margin-top: 0
            &.adSpotBlock--empty
                padding-bottom: 0

    &--emplacement-homePageAsideAdsWrapperTop
        .adSpotBlock--rendered.adSpotBlock--filled
            @include include-media.media(">=min")
                margin: 0 0 20px 0

    &--emplacement-homePageMainHeadlines .storyCard--position-1
            .videoSticker
                &__badge
                    margin-top: 10%
            .podcastSticker
                &__badge
                    margin-top: 10%

    &--emplacement-homePageMainHeadlines .storyCard--position-2,
    &--emplacement-homePageMainHeadlines .storyCard--position-3,
    &--emplacement-homePageMainHeadlines .storyCard--position-4,
    &--emplacement-homePageMainHeadlines .storyCard--position-5,
    &--emplacement-homePageMainHeadlines .storyCard--position-6,
    &--emplacement-homePageMainHeadlines .storyCard--position-7,
    &--emplacement-homePageMainHeadlines .storyCard--position-8
        &__tag,
        &__label,
        &__badgeSectionName
            margin-top: 0
            margin-bottom: 8px
        .videoSticker
            &__badge
                top: 0
                margin: 0
        .podcastSticker
            &__badge
                top: 0
                margin: 0

    &--emplacement-homePageMainHeadlines .homeHeadlinesCard--displayMode-grouped
        .videoSticker
            &__badge
                top: 0
                display: block
        .podcastSticker
            &__badge
                top: 0
                display: block

        @include include-media.media(">=min")
            .videoSticker
                top: 12%
                &__badge
                    top: 0
                    display: block
                    margin:  0
            .podcastSticker
                top: 12%
                &__badge
                    top: 0
                    display: block
                    margin:  0

    @include include-media.media(">=full")
        &--emplacement-homePageMainHeadlines .storyCard--position-1
            .videoSticker
                &__badge
                    margin-left: 17px
            .podcastSticker
                &__badge
                    margin-left: 17px

    // Têtes d'affiche

    &--emplacement-homePageSectionHeadlines14
        .homeContactUs
            @include include-media.media(">=min")
                margin-top: 60px
            @include include-media.media(">=full")
                margin-top: 80px

    // ===============================================================
    // Section Page     ==============================================
    // ===============================================================

    &--emplacement-sectionPageHeaderAd
            .adSpotBlock
                margin-top: 0
                padding-bottom: 30px
                &.adSpotBlock--empty
                    padding-bottom: 0

    &--emplacement-sectionPageMostRecents
        .storyCard
            margin-top: 17px

    &--emplacement-sectionNewsletter
        margin-top: 4em

    // ===============================================================
    // Story Page     ================================================
    // ===============================================================

    &--emplacement-storyPageHeaderAd
            .adSpotBlock
                margin-top: 0
                padding-bottom: 30px
                &.adSpotBlock--empty
                    padding-bottom: 0
