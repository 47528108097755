@use "include-media/dist/include-media"

.contentCardWithNewsletterFallback
    background-color: #f2f2f2
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    height: 600px
    width: 300px
    margin: 0 auto 3rem auto
    @include include-media.media(">=min")
        margin: 0 auto 2rem auto
